import React from 'react'
import Slider from 'react-slick';

export const Ottslider = ({geticonValues}) => {
  const iconsArray = geticonValues.split(',');
    var settings2 = {
        dots: false,
        navigation: true,
        loop: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        speed: 300,
        autoplaySpeed: 3000,
        cssEase: "linear"
      };
  return (
    <>
      <div className='ottslider'>
                                <Slider {...settings2}>
                                {iconsArray.map((iconsData, index) => {
                                  const iconSeparate = iconsData.split('~');
                                  return <div>
                                    <div className='ottimg'>
                                    <img src={window.weburl + iconSeparate[1]} alt={iconSeparate[0]}/>                              
                                    </div>
                                  </div>
                                })}
                                </Slider>
                                </div>
    </>
  )
}
