import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import axios from 'axios';
export const Contactform = () => {
  // Insert Query Start
  const [user_name, setusername] = useState();
  const [user_mobile, setusermobile] = useState();
  const [user_email, setuseremail] = useState();
  // const [states, setStates] = useState([]);
  // const [cities, setCities] = useState([]);
  // const [selectedState, setSelectedState] = useState("");
  // const [selectedCity, setSelectedCity] = useState("");
  // Focus
  const ref_name = useRef(null);
  const ref_mobile = useRef(null);
  const ref_email = useRef(null); 
  // const ref_state = useRef(null);
  // const ref_city = useRef(null);

  const [isDisabled, setIsDisabled] = useState(true);

  // useEffect(() => {
  //   axios
  //     .get(window.weburl + "api/get_states.php")
  //     .then((response) => setStates(response.data))
  //     .catch((error) => console.log(error));
  // }, []);

  // function getCities(state) {
  //   axios
  //     .get(window.weburl + `api/get_cities.php?sid=${state}`)
  //     .then((response) => setCities(response.data))
  //     .catch((error) => console.log(error));
  // }

  const handledisable = () => {
    if ((user_name) && isValidPhone(user_mobile) && (user_mobile)) {
      const element = document.getElementById('submit_button_text');
      element.classList.remove('disabled');
      setIsDisabled(false);
    } else {
      const element = document.getElementById('submit_button_text');
      element.classList.add('disabled');
      setIsDisabled(true);
    }
  };


  function isValidPhone(phone) {
    const regex = /^[6-9]\d{9}$/gi;
    return regex.test(phone);
  }

  const handleSubmit = () => {
    if (!user_name) {
      alert("Please enter name");
      ref_name.current.focus();
      return false;
    }
    else if (!isValidPhone(user_mobile)) {
      alert("Please enter Mobile number");
      ref_mobile.current.focus();
      return false;
    }
    else {
      document.getElementById("submit_button_text").innerText = 'SUBMITTING...';
      const url = window.weburl + "api/insert_hook.php";
      let fData = new FormData();
      fData.append('user_name', user_name);
      fData.append('user_phone', user_mobile);
      fData.append('user_email', user_email);
      // fData.append("user_state", selectedState ? selectedState : "");
      // fData.append("user_city", selectedCity ? selectedCity : "");
      fData.append('td_name', 'gnc');
      fData.append('utm_url', window.location.href);

      axios.post(url, fData)
        .then(response => {alert(response.data)
          console.log(response);
        })
        .catch(error => alert(error));
      document.getElementById("submit_button_text").innerText = 'SUBMIT';
      document.getElementById("user_name").value = '';
      document.getElementById("user_mobile").value = '';
      document.getElementById("user_email").value = '';
      // document.getElementById("selectedState").value = "";
      // document.getElementById("selectedCity").value = "";
    }

    // console.log({
    //   user_name,
    //   user_mobile,
    //   user_email,
    //   selectedState,
    //   selectedCity
    // });


  }
  return (
    <>
      <section id="contactus" className='pb-0'>
        <div className="container">
          <div className="enquireform2">
            <div className="row">
              <div className="web-container">
                <div className="footerform" data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                  <div className="form">
                    <h2>get your connection</h2>

                    <ul>
                      <li>
                        <input type="text" name="user_name" id="user_name" placeholder='Name*' required value={user_name} onChange={(e) => { setusername(e.target.value); }} onKeyUp={handledisable} ref={ref_name} />
                      </li>
                      <li>
                        <input type="tel" name="user_mobile" id="user_mobile" placeholder='Mobile number*' value={user_mobile} onChange={(e) => { setusermobile(e.target.value); }} onKeyUp={handledisable} ref={ref_mobile} />
                      </li>
                      <li>
                        <input type="email" name="user_email" id="user_email" placeholder='Enter your email*' value={user_email} onChange={(e) => { setuseremail(e.target.value); }} ref={ref_email} />
                      </li>
                      <br></br><br></br><br></br><br></br><br></br>
                      {/* <li> */}


                        {/* <select style={{ width: '100%', height: '60px', color: 'white' }}
                          name="selectedState" id="selectedState" value={selectedState} onChange={(event) => {
                            setSelectedState(event.target.value);
                            getCities(event.target.value);
                          }}
                          ref={ref_state}
                        >
                          <option value="">Select State</option>
                          {states.map((state) => (
                            <option key={state.stateid} value={state.stateid}>
                              {state.sname}
                            </option>
                          ))}
                        </select>
                      </li> */}


                      {/* <li>
                        <select style={{ width: '100%', height: '60px', color: 'white' }}
                          name="selectedCity"
                          id="selectedCity"
                          value={selectedCity}
                          onChange={(event) =>
                            setSelectedCity(event.target.value)
                          }
                          ref={ref_city}>
                          <option value="">Select City</option>
                          {cities.map((city) => (
                            <option key={city.cityid} value={city.cityid}>
                              {city.cityname}
                            </option>
                          ))}
                        </select>
                      </li> */}
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                      <li className='buttnprop'><input type="submit" value="" id="submit_button" disabled={isDisabled} onClick={handleSubmit} /><span id="submit_button_text" className='disabled'>SUBMIT</span></li>
                    </ul>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
