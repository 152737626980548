import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import { Footer } from "./components/Footer";
import { Contactform } from "./components/Contactform";
import Bgimage from "./images/covedrive-banner.png";
import Icon01 from "./images/anivirus.webp";
import Icon02 from "./images/backup-cloud.svg";
import Icon03 from "./images/document-search.svg";
import Icon04 from "./images/transaction.webp";
import Icon05 from "./images/quick-action.webp";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Antivirusannualy } from "./components/Antivirusannualy";
import axios from "axios";

export const CoveDrive = () => {
  const [pagedata, setPagedata] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const response = await axios.get(
        window.weburl + `api/plans.php?catID=15&type=ym`
      );
      if (mounted) {
        setPagedata(response.data);
      }
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, []);

  console.log(pagedata);
  if (pagedata.length === 0) {
    console.log("no data");
    return null;
  }

  return (
    <>
      <Header />
      <section id="banner" className="p-0">
        <div
          className="banner"
          style={{ backgroundImage: "url(" + Bgimage + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div
                  className="bannercont"
                  data-aos="zoom-in"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                >
                  <h2 style={{ textShadow: "0 2px 8px #555" }}>
                    Store your files and documents in a secure cloud.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="netplusadvantage" className="pb-0">
        <div className="netplusadvantages">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div
                  className="title"
                  data-aos="fade-up"
                  data-aos-offset="50"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                >
                  <span>Cloud Storage</span>
                  <h3>
                    Securely Store and Access Your Important Files Anytime,
                    Anywhere
                  </h3>
                </div>
                <div
                  className="advantages"
                  data-aos="fade-up"
                  data-aos-offset="50"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                >
                  <div className="advantagesbox">
                    <div className="advantageicon">
                      <span>
                        <img src={Icon01} alt="" />
                      </span>
                    </div>
                    <div className="advantagecont">
                      <p>Secure your files with True End-to-End Encryption</p>
                    </div>
                  </div>
                  <div className="advantagesbox">
                    <div className="advantageicon">
                      <span>
                        <img src={Icon02} alt="" />
                      </span>
                    </div>
                    <div className="advantagecont">
                      <p>Auto-backup photos & videos to CoveDrive</p>
                    </div>
                  </div>
                  <div className="advantagesbox">
                    <div className="advantageicon">
                      <span>
                        <img src={Icon03} alt="" />
                      </span>
                    </div>
                    <div className="advantagecont">
                      <p>Find docs easily with Smart Tags & Labels</p>
                    </div>
                  </div>
                  <div className="advantagesbox">
                    <div className="advantageicon">
                      <span>
                        <img src={Icon04} alt="" />
                      </span>
                    </div>
                    <div className="advantagecont">
                      <p>Scan docs in a flash with Cove Identity App</p>
                    </div>
                  </div>
                  <div className="advantagesbox">
                    <div className="advantageicon">
                      <span>
                        <img src={Icon05} alt="" />
                      </span>
                    </div>
                    <div className="advantagecont">
                      <p>Share safely; control access through Cove</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {pagedata.data ? (
        <section id="antiviruspacks" className="p-0">
          <div className="antiviruspacks">
            <div className="container">
              <div className="row">
                <div className="heading">
                  <h4>CHOOSE YOUR PERFECT PACKAGE</h4>
                </div>
                <div className="planstab antivirus">
                  <Tabs>
                    <TabList>
                      <Tab>Annual Packages</Tab>
                      <Tab>Monthly Packages</Tab>
                    </TabList>
                    {pagedata.data.yearly ? (
                      <TabPanel>
                        <div className="planstabpanel">
                          <Antivirusannualy planDB={pagedata.data.yearly} />
                        </div>
                      </TabPanel>
                    ) : null}
                    {pagedata.data.monthly ? (
                      <TabPanel>
                        <div className="planstabpanel">
                          <Antivirusannualy planDB={pagedata.data.monthly} />
                        </div>
                      </TabPanel>
                    ) : null}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
      {/* <Contactform /> */}
      <Footer />
    </>
  );
};
