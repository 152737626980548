import React from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import Bgimage from './images/smart-city-banner.webp'
import Icon01 from './images/Smart01.webp'
import Icon02 from './images/Smart02.webp'
import Icon03 from './images/Smart03.webp'
import Icon04 from './images/Smart04.webp'
import Icon05 from './images/Smart05.webp'
import Freewifi from './images/free-banner.webp'

export const Smartcity = () => {
  return (
    <>
        <Header />
        <section id='banner' className='p-0'>
            <div className='banner' style={{backgroundImage:'url('+Bgimage+')'}}>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                          <div className='bannercont'>
                              <h2>Netplus smart city solution</h2>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='smartcity'>
            <div className='smartcity'>
                    <div className='container'>
                            <div className='row'>
                                    <div className='web-container'>
                                                <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                                <span>SMARTY CITY PROJECTs</span>
                                                <h3>Smart Solution <br/> for the cities of tomorrow</h3>
                                            </div>
                                            <div className='smartcont' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            <p>Today’s cities face a variety of challenges, including job creation, economic growth, environmental sustainability, and social resilience. With the Government’s mission of building 100 smart cities in India to drive economic growth and improve the quality of life for its citizens, there is a need of smart digital infrastructure to offer citizen amenities such as connected transport, wireless broadband, security services, traffic management, safety etc. This is where Netplus Broadband comes in with its smart solutions. </p>
                                            <p>Netplus has developed state-of-art digital solutions that cater to the needs of  the smart cities project. The integrated smart city solutions, such as security, surveillance and traffic management, disaster recovery & management, cyber security, network integration etc., enable you to remotely operate devices and access services anytime anywhere. Netplus broadband had partnered with reputed organizations for efficient delivery of services. The company has ample experience of working with state and central govt. institutions of smart cities projects across India. The company has experience of working with the central and state government of smart cities projects.</p>
                                            </div>
                                    </div>
                            </div>
                    </div>
            </div>
        </section>
        <section id='solutionbanner'>
                <div className='solutionbanner'>
                        <div className='container'>
                            <div className='row'>
                                    <div className='sbanner'>
                                        <div className='web-container'>
                                            <div className='solutionrow'>
                                        <div className='solutionimg'>
                                        <img src={Freewifi} alt='' />
                                        </div>
                                        <div className='solutioncont'>
                                            <h4>Free Public Wi-fi</h4>
                                            <p>Enjoy Netplus Free Public Wi-fi at Golden Temple Amritsar & Grand Walk Mall, Ludhiana.</p>
                                        </div>
                                        </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                </div>
        </section>
        <section id='netplusadvantage'>
                <div className='netplusadvantages'>
                        <div className='container'>
                                <div className='row'>
                                    <div className='web-container'>
                                            <div className='heading' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            <h4>Netplus Advantages</h4>
                                            </div>
                                            <div className='advantages' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon01} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Delivered large scalable projects </p>
                                                    </div>
                                                </div>
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon02} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Vast experience of working with central & state governments</p>
                                                    </div>
                                                </div>
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon03} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Robust infrastructure & network services </p>
                                                    </div>
                                                </div>
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon04} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Strong partnership with leading OEMs</p>
                                                    </div>
                                                </div>
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon05} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Trained and experienced professionals </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className='text-center col-12 pull-left'>To know more about Netplus Smart City projects, write us at : <a href='mailto:ebssales@netplus.co.in'>ebssales@netplus.co.in</a></h4>
                                    </div>
                                </div>
                        </div>
                </div>
        </section>
        {/* <Contactform /> */}
        <Footer />
    </>
  )
}
