import React from 'react'
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

var decodeHTML = function (html) {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
    return {__html: decodeHTML(content)};
}

export const Antivirusannualy = ( {planDB} ) => {
    var settings3 = {
        dots: false,
        navigation: true,
        loop: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        speed: 300,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1340,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      };
  return (
    <>
     <div className='planslider blckarrow'>
                        <Slider {...settings3}>
                        {Object.keys(planDB).map((value) => {
                          const newValues = planDB[value].features.split('|_');
                        // {.map(getData => {
                          return <div>
                            <div className='plansitem'>
                              <div className='ptag'>
                                <h5 dangerouslySetInnerHTML={createMarkup(planDB[value].pname)}></h5>
                              </div>
                              <div className='price mb-0 w-100'>
                                <div className='sprice m-0'>
                                  <h6>{planDB[value].price}<small>/{planDB[value].ptype}</small></h6>
                                </div>
                              </div>
                              <div className='price'>
                              {planDB[value].features?
                                <ul>
                                  {newValues.map(featuresValue => (
                                    <>
                                    {featuresValue ?
                                      <li dangerouslySetInnerHTML={createMarkup(featuresValue)}></li>
                                      : null}
                                      </>
                                      ))}
                                </ul>
                                : null}
                                <div className='buttnprop'>
                                   <Link to={'/book-now/' + planDB[value].plan_id}><span>Book Now </span> </Link>                                   
                                </div>
                              </div>
                            </div>
                          </div>
                          })}
                        </Slider>
                      </div>
    </>
  )
}
