import React, { useState, useEffect, useRef } from 'react'
import Header from './components/Header'
import { Contactform } from './components/Contactform'
import { Footer } from './components/Footer'
import Bgimage from './images/netplus-hd-bundle.webp'
import Icon01 from './images/ad04.webp'
import Icon02 from './images/unlimited-data.webp'
import Icon03 from './images/unlimited-call.webp'
import Icon04 from './images/ott-subscribtion.webp'
import Icon05 from './images/all-in-one.webp'
import { Link } from 'react-router-dom'
import Plan_bg from "./images/Plan_Bg.webp"
import { Ottslider } from './components/Ottslider'
import axios from 'axios';

export const Netplushd = () => {

  var decodeHTML = function (html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  function createMarkup(content) {
    return { __html: decodeHTML(content) };
  }

  const [pagedata, setPagedata] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const response = await axios.get(window.weburl + `api/plans.php?catID=8`)
      if (mounted) {
        setPagedata(response.data);
      }
    }

    loadData();

    return () => {
      mounted = false;
    };
  }, []);

  console.log(pagedata);
  if (pagedata.length === 0) {
    console.log('no data');
    // return null;
  }

  return (
    <>
      <Header />
      <section id='banner' className='p-0'>
        <div className='banner' style={{ backgroundImage: 'url(' + Bgimage + ')' }}>
          <div className='container'>
            <div className='row'>
              <div className='web-container'>
                <div className='bannercont' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                  <h2>Narrate stories as much as you like.</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='hdbundle'>
        <div className='hdbundle'>
          <div className='container'>
            <div className='row'>
              <div className='web-container'>
                <div className='gradienticons' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                  <ul>
                    <li><span><img src={Icon01} alt='' /></span><p>Live HD TV</p></li>
                    <li><span><img src={Icon02} alt='' /></span><p>Unlimited Data</p></li> 
                    <li><span><img src={Icon03} alt='' /></span><p>Free Voice Calls</p></li>
                    <li><span><img src={Icon04} alt='' /></span><p>Free OTT Subscription</p></li>
                    <li><span><img src={Icon05} alt='' /></span><p>All in one plan</p></li>
                  </ul>
                </div>
                <div className='title' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                  <span>HD BUNDLE</span>
                  <h4>Save more with Big Combos</h4>
                  <h3>Broadband + HD Cable Plans  and Digital Cable Entertainment Plans</h3>
                  {/* <h3>Digital Cable Entertainment Plans</h3> */}

                </div>
                {pagedata.data ?
                  <div className='bundlestn' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                    {pagedata.data.map(getData => {
                      const newValues = getData.features.split('|_');
                      return <div className='bundlebox'>
                        <h4><i className='finr'></i>{getData.price}</h4>
                        <div className='image_plans_div'>
                          <div className="image_plan">
                            <img src={Plan_bg} alt="Plan Image" />
                          </div>
                          <p dangerouslySetInnerHTML={createMarkup(getData.ptitle)}></p>
                        </div>
                        {getData.features ?
                          <ul>
                            {newValues.map(featuresValue => (
                              <>
                                {featuresValue ?
                                  <li dangerouslySetInnerHTML={createMarkup(featuresValue)}></li>
                                  : null}
                              </>
                            ))}
                          </ul>
                          : null}
                        {getData.icon_values ?
                          <div className='entertainment'>
                            <Ottslider geticonValues={getData.icon_values} />
                          </div>
                          : null}
                        <div className='buttnprop'>
                          <Link to={'/book-now/' + getData.plan_id}><span>Book Now </span></Link>
                        </div>
                      </div>
                    })}
                  </div>
                  : null}

              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='packs'>
        <div className='packs coreadvantages'>
          <div className='container'>
            <div className='row'>
              <div className='planbanner'>
                <div className='web-container'>
                  <div className='packstn'>
                    <div className='squarecont' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                      <ul>
                        <li><span>Pay for <strong>3</strong> months & get Free</span>
                          <ul>
                            <li><strong>HD</strong> Box	</li>
                            <li><strong>Wi-fi</strong> Modem</li>
                            <li><strong>Zero</strong> Installation Charges</li>
                          </ul>
                        </li>
                        <li><span>Pay for <strong>5</strong> months & get <strong>1</strong> Month Free</span></li>
                        <li><span>Pay for <strong>10</strong> months & get <strong>2</strong> Months Free</span>
                        </li>
                        {/* <li><span>Pay for <strong>12</strong> month & Get <strong>3</strong> Month Free</span></li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Contactform /> */}
      <Footer />
    </>
  )
}
