import React from "react";
import Header from "./components/Header";
import { Footer } from "./components/Footer";
import { Contactform } from "./components/Contactform";

export const Refundpolicy = () => {
  return (
    <>
      <Header />
      <section id="banner" className="p-0">
        <div className="banner booknow filter m-0 h-120"></div>
      </section>
      <section id="contentpage">
        <div className="contentpage">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="heading">
                  <h4>CANCELLATION & REFUND POLICY</h4>
                </div>
                <p>24 to 48 hours, however the concerned bank may take 4-7 working days to post the credit to your account</p>
                <p>In case you have received a success confirmation but not received credit towards your bill payment or any other case you are welcome to lodge a complaint via any means as mentioned on our contact us page.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Contactform /> */}
      <Footer />
    </>
  );
};
