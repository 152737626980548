import React from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import Bgimage from './images/security-camera.webp'
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Slider from 'react-slick';
import Music from './images/hungama-ply-music.webp'
import Game from './images/gamezone.webp'

export const Hungama = () => {
    var settings3 = {
        dots: false,
        navigation: true,
        loop: false,
        infinite: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
          autoplay: true,
          speed: 300,
          autoplaySpeed: 3000,
          cssEase: "linear",
          responsive: [
            {
              breakpoint: 1340,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 680,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
        };
  return (
    <>
        <Header />
        <section id='banner' className='p-0'>
            <div className='banner' style={{backgroundImage:'url('+Bgimage+')'}}>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                          <div className='bannercont' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                              <h2>Ad-free music with unlimited hungama</h2>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='antiviruspacks' className='p-0'>
            <div className='antiviruspacks'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='heading' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                          <h4>Netplus HUNGAMA PLANS</h4>
                        </div>
                    <div className='planstab antivirus' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" >
                <Tabs>
                  <TabList>
                    <Tab>BROADBAND USERS</Tab>
                    <Tab>IPTV USERS</Tab>
                  </TabList>

                  <TabPanel>
                    <div className='planstabpanel'>
                    <div className='bundlestn'>
                                            <div className='bundlebox'>
                                                <h4><i className='finr'></i>99</h4>
                                                <div className='price'>
                                                <ul>
                                                    <li><strong>1 Month</strong> Validity </li>
                                                    <li>Hungama Play</li>
                                                    <li> Hungama Music</li>     
                                                    <li> Hungama Gameszone</li>                                          
                                                </ul>
                                                </div>
                      <div className='buttnprop'>
                      <Link to='/book-now'><span>Book Now </span></Link>
                      
                    </div>
                                            </div>
                                            <div className='bundlebox'>
                                                <h4><i className='finr'></i>268</h4>
                                                <div className='price'>
                                                <ul>
                                                    <li><strong>3 Months </strong>Validity </li>
                                                    <li>Hungama Play</li>
                                                    <li> Hungama Music</li>     
                                                    <li> Hungama Gameszone</li>                                          
                                                </ul>
                                                </div>
                      <div className='buttnprop'>
                      <Link to='/book-now'><span>Book Now </span></Link>
                      
                    </div>
                                            </div>
                                            <div className='bundlebox'>
                                                <h4><i className='finr'></i>890</h4>
                                                <div className='price'>
                                                <ul>
                                                    <li><strong>12 Months</strong> Validity </li>
                                                    <li>Hungama Play</li>
                                                    <li> Hungama Music</li>     
                                                    <li> Hungama Gameszone</li>                                          
                                                </ul>
                                                </div>
                      <div className='buttnprop'>
                      <Link to='/book-now'><span>Book Now </span></Link>
                      
                    </div>
                                            </div>
                                        </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                  <div className='planstabpanel'>   
                    <Slider {...settings3}>
                        <div>
                        <div className='bundlebox'>
                                                <h4><i className='finr'></i>49</h4>
                                                <div className='price'>
                                                <ul>
                                                    <li><strong>1 Month</strong> Validity </li>
                                                    <li>Hungama Play</li>
                                                    <li><strike>Hungama Music</strike></li>     
                                                    <li><strike>Hungama Gameszone</strike></li>                                          
                                                </ul>
                                                </div>
                      <div className='buttnprop'>
                      <Link to='/book-now'><span>Book Now </span></Link>
                      
                    </div>
                                            </div>
                        </div>
                        <div>
                        <div className='bundlebox'>
                                                <h4><i className='finr'></i>133</h4>
                                                <div className='price'>
                                                <ul>
                                                    <li><strong>3 Months</strong> Validity </li>
                                                    <li>Hungama Play</li>
                                                    <li><strike>Hungama Music</strike></li>     
                                                    <li><strike>Hungama Gameszone</strike></li>                                          
                                                </ul>
                                                </div>
                      <div className='buttnprop'>
                      <Link to='/book-now'><span>Book Now </span></Link>
                      
                    </div>
                                            </div>
                        </div>
                        <div>
                        <div className='bundlebox'>
                                                <h4><i className='finr'></i>236</h4>
                                                <div className='price'>
                                                <ul>
                                                    <li><strong>6 Months</strong> Validity </li>
                                                    <li>Hungama Play</li>
                                                    <li><strike>Hungama Music</strike></li>     
                                                    <li><strike>Hungama Gameszone</strike></li>                                          
                                                </ul>
                                                </div>
                      <div className='buttnprop'>
                      <Link to='/book-now'><span>Book Now </span></Link>
                      
                    </div>
                                            </div>
                        </div>
                        <div>
                        <div className='bundlebox'>
                                                <h4><i className='finr'></i>441</h4>
                                                <div className='price'>
                                                <ul>
                                                    <li><strong>12 Months</strong> Validity </li>
                                                    <li>Hungama Play</li>
                                                    <li><strike>Hungama Music</strike></li>     
                                                    <li><strike>Hungama Gameszone</strike></li>                                          
                                                </ul>
                                                </div>
                      <div className='buttnprop'>
                      <Link to='/book-now'><span>Book Now </span></Link>
                      
                    </div>
                                            </div>
                        </div>
                    </Slider>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
              </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='hungamafeatures'>
                <div className='hungamafeatures'>
                        <div className='container'>
                            <div className='row'>
                                <div className='web-container'>
                                    
                                <div className='heading' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <h4>HUNGAMA FEATURES</h4>
                                </div>
                                <div className='hungamafrow'>
                                    <div className='hungamabox' data-aos="fade-right" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            <div className='hungamacircle'>
                                                <div className='hungamaimages'>
                                                <img src={Music} alt='Hungama Music'/>
                                                </div>
                                                </div>
                                            <div className='hungamacont'>
                                                <ul>
                                                    <li> 1st media app with short & long format content.</li>
                                                    <li>5000+ hollywood, bollywood, regional & originals in HD.</li>
                                                    <li>10 million songs & music videos.</li>
                                                    <li>Ad-free content.</li>
                                                    <li>Full length music videos.</li>
                                                    <li>10,000+ music, lifestyle, gossip videos in HD.</li>
                                                    <li>Unlimited downloads of movies & music.</li>
                                                </ul>
                                            </div>
                                    </div>
                                    <div className='hungamabox' data-aos="fade-left" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            <div className='hungamacircle'>
                                                <div className='hungamaimages'>
                                                <img src={Game} alt='Hungama Gamezone'/>
                                                </div>
                                                </div>
                                            <div className='hungamacont'>
                                                <ul>
                                                    <li>500+ games.</li>
                                                    <li>World's best HTML5 games.</li>
                                                    <li>Titles that casual gamers love.</li>
                                                    <li>No installation is required.</li>
                                                    <li>Extremely low memory requirements.</li>
                                                    <li>Ad-free content.</li>
                                                    <li>New games added frequently.</li>
                                                </ul>
                                            </div>
                                    </div>
                                </div>
                                
                                </div>
                            </div>
                        </div>
                </div>
        </section>
        {/* <Contactform /> */}
        <Footer />
    </>
  )
}
