import React from 'react'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import Header from './components/Header'
import Bgimage from './images/voice-banner.webp'

export const Voice = () => {
  return (
    <>
        <Header />
        <section id='banner' className='p-0'>
            <div className='banner' style={{backgroundImage:'url('+Bgimage+')'}}>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                          <div className='bannercont lefttextalign'  data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                              <h2>Smart Voice Telephony</h2>                             
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='enterprise'>
            <div className='enterprise'>
                    <div className='container'>
                            <div className='row'>
                                    <div className='web-container'>
                                            <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                                <span>SMART TELEPHONY</span>
                                                <h3>Fixed Line Services – Smart Telephony</h3>
                                            </div>                                           
                                            <div className='smartcont' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                                <p>Netplus Next Gen “Smart Telephony Services” works over broadband Network and will offer Unlimited local & STD Calling. This service offers customers HD quality voice calls along with faster call set up time and host of new features.USP of this service is that calls can be received both from Fixed line and Mobile.Including Freedom of Movement within WIFY.</p>
                                                </div>
                                    </div>
                            </div>
                    </div>
            </div>
        </section>
        <section id='filter'>
                <div className='filter'>
                    <div className="container">
                        <div className="row">
                            <div className="web-container">
                                <div className="footerform" data-aos="fade-up" data-aos-offset="10" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                        <div className='heading'>
                                            <h4>Get it today</h4>
                                        </div>
                                    <div className="form">
                                        <form >
                                            <ul>
                                                <li>
                                                    <input type="text" name="name" placeholder='Your name' required />
                                                </li>
                                                <li>
                                                    <input type="tel" name="phone" placeholder='Your mobile number' pattern="^[6-9]\d{9}$" minLength="10" maxLength="10" required />
                                                </li>
                                                <li>
                                                <input type="text" name="city" placeholder='Enter city' required />
                                                </li>
                                                <li>
                                                <input type="text" name="Operator" placeholder='Operator Name' required />
                                                </li>
                                                <li className='buttnprop'><input type="submit" value="" id="submit_button" /><span id="submit_button_text">SUBMIT</span></li>
                                            </ul>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        {/* <Contactform /> */}
        <Footer/>
    </>
  )
}
