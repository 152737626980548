import React, { useState } from 'react';
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import Bgimage from './images/about-nteplus-banner.webp'
import Icon01 from './images/Home01.webp'
import Icon02 from './images/Home02.webp'
import Icon03 from './images/ip04.webp'
import Icon04 from './images/Home09.webp'
import Icon05 from './images/Home07.webp'
import Icon06 from './images/Smart04.webp'
import Icon07 from './images/Smart01.webp'
import { Numbers } from './components/Numbers'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export const Aboutus = () => {
    const [state, setState] = useState({ turnover: 1, workforce: 1, states: 1, cities: 1 });
  return (
    <>
    <Header />
    <section id='banner' className='p-0'>
            <div className='banner' style={{backgroundImage:'url('+Bgimage+')'}}>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                          <div className='bannercont'  data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                              <h2>Next-Gen Speeds and Services</h2>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='homeautomation'>
                <div className='aboutnetplus'>
                        <div className='container'>
                                <div className='row'>
                                    <div className='web-container'>
                                        <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            <span>NETPLUS BROADBAND</span>
                                        </div>
                                        <div className='smartcont' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                        <p>The preferred broadband service provider of Punjab - revered for its superfast connection speeds and high customer retention rate, Netplus Broadband is the dominant and fastest growing ISP in the region, providing next-gen services such as High Speed Broadband, Smart Telephony & OTT.</p>
                                            <p>The company offers high speed internet through the FTTH network to residential, SME and corporate customers across major cities and townships of urban Punjab, and via hotspot wireless broadband in rural Punjab. With an extensive FTTH network across 8 states, the company delivers world-class services to over 7 lakh customers.</p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                </div>
      </section>
      <section id='netplushighlights'>
      <div className='retails'>
          <div className='container'>
            <div className='row'>
                <div className='web-container'>
                      <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                        <span>SERVICES</span>
                      </div>
                      <div className='circleicons' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                            <ul>
                              <li><span><img src={Icon01} alt=''/></span><p>FTTH</p></li>
                              <li><span><img src={Icon02} alt=''/></span><p>OTT</p></li>
                              <li><span><img src={Icon03} alt=''/></span><p>Voice Services</p></li>
                              <li><span><img src={Icon04} alt=''/></span><p>Home Security</p></li>
                              <li><span><img src={Icon05} alt=''/></span><p>Home Automation</p></li>
                              <li><span><img src={Icon06} alt=''/></span><p>Enterprise Solutions</p></li>
                              <li><span><img src={Icon07} alt=''/></span><p>Government Projects</p></li>
                            </ul>
                        </div>
                    </div>
                    </div>
              </div>
        </div>
      </section>
      <section id='netplusnumber'>
      <div className='container'>
                                    <div className='row'>
                                          <div className='web-container'>
                                                
                      <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                        <span>Snapshot</span>
                      </div>
                                          </div>
                                    </div>
                              </div>
                            <div className='number aboutnetplus p-0'>
                            
          <div className='container'>
            <div className='row'>
                <div className='web-container'>
                <Numbers />
                    <div className='highlights'>
                    <ul ><li>Triple Play Services<span>Voice + Video+ Data</span></li><li>State-Of-The-Art<span>NOC &amp; DWDM network</span></li><li>Market leader in SOGA<span>(Share of Gross Ads)</span></li><li>Collaboration with Facebook<span>Free Wi-Fi services</span></li></ul>
                    </div>
                        </div>
                    </div>
                    </div>
            </div>
      </section>
    <Contactform />
    <Footer />
    </>
  )
}
