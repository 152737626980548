import React from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import Bgimage from './images/banner.webp'
import Icon01 from './images/ip02.webp'
import Icon02 from './images/ip03.webp'
import Icon03 from './images/ip04.webp'
import Icon04 from './images/ip05.webp'
import Icon05 from './images/ip06.webp'
import Icon06 from './images/ip07.webp'
import Icon07 from './images/ip08.webp'
import Icon08 from './images/ip09.webp'
import { Contactform } from './components/Contactform'

export const Ipbox = () => {
  return (
    <>
        <Header />
        <section id='banner' className='p-0'>
            <div className='banner' style={{backgroundImage:'url('+Bgimage+')'}}>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                          <div className='bannercont'>
                              <h2>Ab baaten hongi non-stop</h2>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='smartcity'>
            <div className='smartcity'>
                    <div className='container'>
                            <div className='row'>
                                    <div className='web-container'>
                                            <div className='title'>
                                                <span>Ip Box</span>
                                            </div>
                                            <div className='smartcont'>
                                            <p>PBX stands for Private Branch Exchange. A PBX system is a private internal telephone system that enables internal and external communication. PBX phone systems connect multiple extensions within an office to each other—effectively consolidating multiple phone lines into one—as well as to a small number of outside lines. The typical IP PBX can also switch calls between a VoIP user and a traditional telephone user, or between two traditional telephone users in the same way that a conventional PBX does. Netplus provides pioneering telephony systems that address the communication woes of a growing enterprise/business.</p>
                                            </div>
                                    </div>
                            </div>
                    </div>
            </div>
        </section>
        <section id='netplusadvantage'>
                <div className='netplusadvantages'>
                        <div className='container'>
                                <div className='row'>
                                    <div className='web-container'>
                                            <div className='heading'>
                                            <h4>Advantages of Netplus IP PBx Solution</h4>
                                            </div>
                                            <div className='advantages'>
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon01} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Zero infrastructure cost </p>
                                                    </div>
                                                </div>
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon02} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Help businesses reduces phone bills and multiple connections </p>
                                                    </div>
                                                </div>
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon03} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Smart and accurate call routing</p>
                                                    </div>
                                                </div>
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon04} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Greater control of managing all incoming calls</p>
                                                    </div>
                                                </div>
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon05} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Auto-attendant answer calls to attend calls</p>
                                                    </div>
                                                </div>
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon06} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Handle both outbound and inbound calls in one go</p>
                                                    </div>
                                                </div>
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon07} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Cloud-based VoIP option for calling </p>
                                                    </div>
                                                </div>
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon08} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Get usage report and reduces bills </p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                    </div>
                                </div>
                        </div>
                </div>
        </section>
        {/* <Contactform /> */}
        <Footer />
    </>
  )
}
