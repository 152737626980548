import React from 'react'

export const Networkingsvg = (props) => {
  return (
   <>
      <svg
    xmlns="http://www.w3.org/2000/svg"
    width={90}
    height={90}
    aria-hidden="true"
    aria-labelledby="svgcid--2kv5olfqg3yf"
    data-bbox="7.5 18.8 185.1 162.5"
    data-type="color"
    viewBox="7.5 18.8 185.1 162.5"
    {...props}
  >
    <defs>
      <style />
    </defs>
    <title id="svgcid--2kv5olfqg3yf" />
    <path
      fill="url(#my-cool-gradient)"
      d="M175.1 82.6c-8.5 0-15.6 6.1-17.1 14.2l-40.8 1.9c-.8-2.7-2.2-5.2-4.1-7.2l25.6-39c1.8.7 3.8 1.1 5.9 1.1 9.6 0 17.4-7.8 17.4-17.4 0-9.6-7.8-17.4-17.4-17.4-9.6 0-17.4 7.8-17.4 17.4 0 4.6 1.8 8.8 4.8 11.9l-25.6 39c-1.8-.7-3.8-1.1-5.9-1.1-2 0-4 .4-5.8 1l-25-38.7c3-3.1 4.9-7.4 4.9-12 0-9.6-7.8-17.4-17.4-17.4-9.6 0-17.4 7.8-17.4 17.4 0 9.6 7.8 17.4 17.4 17.4 2 0 4-.4 5.8-1l25 38.8c-2 2.1-3.4 4.6-4.2 7.4l-42-1.5c-1.7-7.8-8.7-13.5-16.9-13.5-9.6 0-17.4 7.8-17.4 17.4 0 9.6 7.8 17.4 17.4 17.4 8.2 0 15-5.6 16.9-13.2l41.8 1.5c.6 3 2 5.7 3.9 8l-23.6 32.8c-2-.8-4.3-1.3-6.6-1.3-9.6 0-17.4 7.8-17.4 17.4 0 9.6 7.8 17.4 17.4 17.4 9.6 0 17.4-7.8 17.4-17.4 0-4.4-1.6-8.3-4.3-11.4L94 119.7c2 .8 4.3 1.3 6.6 1.3 2.4 0 4.7-.5 6.8-1.4l24.1 33c-2.6 3-4.2 7-4.2 11.3 0 9.6 7.8 17.4 17.4 17.4 9.6 0 17.4-7.8 17.4-17.4 0-9.6-7.8-17.4-17.4-17.4-2.4 0-4.7.5-6.8 1.4l-24.1-33c1.9-2.3 3.3-5 3.9-8.1l40.8-1.9c2.1 7.3 8.8 12.6 16.7 12.6 9.6 0 17.4-7.8 17.4-17.4-.1-9.7-7.9-17.5-17.5-17.5zM144.6 27c5.1 0 9.3 4.1 9.3 9.3s-4.1 9.3-9.3 9.3-9.3-4.1-9.3-9.3 4.2-9.3 9.3-9.3zm-96.7 9.2c0-5.1 4.1-9.3 9.3-9.3s9.3 4.1 9.3 9.3-4.1 9.3-9.3 9.3-9.3-4.1-9.3-9.3zm-23 74.3c-5.1 0-9.3-4.1-9.3-9.3s4.1-9.3 9.3-9.3 9.3 4.1 9.3 9.3-4.2 9.3-9.3 9.3zM57.1 173c-5.1 0-9.3-4.1-9.3-9.3s4.1-9.3 9.3-9.3 9.3 4.1 9.3 9.3-4.2 9.3-9.3 9.3zm34.2-69.5c0-5.1 4.1-9.3 9.3-9.3s9.3 4.1 9.3 9.3-4.1 9.3-9.3 9.3-9.3-4.2-9.3-9.3zm62.6 60.2c0 5.1-4.1 9.3-9.3 9.3s-9.3-4.1-9.3-9.3 4.1-9.3 9.3-9.3 9.3 4.2 9.3 9.3zm21.2-54.4c-5.1 0-9.3-4.1-9.3-9.3s4.1-9.3 9.3-9.3 9.3 4.1 9.3 9.3-4.2 9.3-9.3 9.3z"
      data-color={1}
    />
  </svg>
   </>
  )
}
