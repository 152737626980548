import React from 'react'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import Bgimage from './images/security-camera.webp'
import Img01 from './images/Networking.webp'
import Img02 from './images/Data-center.webp'
import Img03 from './images/Cyber-security.webp'
import Img04 from './images/Collaboration.webp'
import Img05 from './images/wifi-mobility.webp'
import Img06 from './images/Analytics.webp'
import Img07 from './images/EDGE-Computing.webp'
import Img08 from './images/UNMS-Automation.webp'
import Img09 from './images/Cloud-products.webp'
import Img10 from './images/Saas.webp'
import Img11 from './images/Software.webp'
import Img12 from './images/End-point.webp'
import Iconn01 from './images/SPicon.webp'
import Iconn02 from './images/eicon.webp'
import Iconn03 from './images/sme.webp'
import Iconn04 from './images/gov.webp'
import { Analytics } from './components/Analytics'
import { Artificialsvg } from './components/Artificialsvg'
import { Cloudsvg } from './components/Cloudsvg'
import { Collaborationsvg } from './components/Collaborationsvg'
import { Datacentersvg } from './components/Datacentersvg'
import { Networkingsvg } from './components/Networkingsvg'
import { Thinksvg } from './components/Thinksvg'
import { Mobility } from './components/Mobility'
import { Securitysvg } from './components/Securitysvg'
import { Softwaresvg } from './components/Softwaresvg'
import { Softnetworking } from './components/Softnetworking'

export const Securitycamera = () => {
  return (
    <>
        <Header />
        <section id='banner' className='p-0'>
            <div className='banner' style={{backgroundImage:'url('+Bgimage+')'}}>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                          <div className='bannercont' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                              <h2>Smart Surveillance Solution</h2>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='smartcity'>
            <div className='smartcity'>
                    <div className='container'>
                            <div className='row'>
                                    <div className='web-container'>
                                                <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                                <span>System Intigration Projects</span>
                                                <h3>Products by technology</h3>
                                                <p>Need some help in choosing what products fit your IT need?<br /> Contact us @ CTAP SYSTEMS</p>
                                            </div>
                                            <div className='protechnology'>
                                        <div className='technobox'  data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                          <div className='technoimg'>
                                            <img src={Img01} alt='' />
                                            </div>
                                            <div className='technocont'>
                                            <h4>Networking</h4>
                                            <ul>
                                              <li>Enterprise Networking</li>
                                              <li>Data Center Networking</li>
                                              <li>Cloud Networking</li>
                                              <li>Optical Networking</li>
                                              <li>Campus Networking</li>
                                              <li>Software-defined Networking</li>
                                              <li>Wide-Area Networking</li>
                                            </ul>
                                            </div>
                                          </div>
                                         <div className='technobox'  data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                          <div className='technoimg'>
                                            <img src={Img02} alt='' />
                                            </div>
                                            <div className='technocont'>
                                            <h4>Data Center</h4>
                                            <ul>
                                              <li>Servers</li>
                                              <li>Storage</li>
                                              <li>Backup</li>
                                              <li>Security</li>
                                              <li>Load Balancers</li>
                                              <li>Virtual Networking</li>
                                              <li>Physical Data Center</li>
                                              <li>Products</li>
                                            </ul>
                                            </div>
                                          </div>
                                         <div className='technobox'  data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                          <div className='technoimg'>
                                            <img src={Img03} alt='' />
                                            </div>
                                            <div className='technocont'>
                                            <h4>Cyber Security</h4>
                                            <ul>
                                              <li>Cyber Range</li>
                                              <li>Data Center Security</li>
                                              <li>Network Security</li>
                                              <li>Web Security</li>
                                              <li>Application Security</li>
                                              <li>Host Memory Security</li>
                                              <li>End Point Security</li>
                                            </ul>
                                            </div>
                                          </div>
                                         <div className='technobox'  data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                          <div className='technoimg'>
                                            <img src={Img04} alt='' />
                                            </div>
                                            <div className='technocont'>
                                            <h4>Collaboration</h4>
                                            <ul>
                                              <li>Voice Bot - AI Digital Agent</li>
                                              <li>Chat Bot - AI chat engine</li>
                                              <li>Unified Communication</li>
                                              <li>Contact Center Solution</li>
                                              <li>Video Conferencing</li>
                                              <li>A/V solution</li>
                                              <li>Board Room solutions</li>
                                            </ul>
                                            </div>
                                          </div>
                                         <div className='technobox'  data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                          <div className='technoimg'>
                                            <img src={Img05} alt='' />
                                            </div>
                                            <div className='technocont'>
                                            <h4>WI-FI & Mobility</h4>
                                            <ul>
                                              <li>On-prem Access Point</li>
                                              <li>Cloud-managed Access</li>
                                              <li>Point</li>
                                              <li>Management of AP</li>
                                              <li>Secure Wi-fi</li>
                                            </ul>
                                            </div>
                                          </div>
                                         <div className='technobox'  data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                          <div className='technoimg'>
                                            <img src={Img06} alt='' />
                                            </div>
                                            <div className='technocont'>
                                            <h4>Analytics</h4>
                                            <ul>
                                              <li>Data Center Analytics</li>
                                              <li>Network Analytics</li>
                                              <li>Security Analytics</li>
                                              <li>Cloud Analytics</li>
                                            </ul>
                                            </div>
                                          </div>
                                         <div className='technobox'  data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                          <div className='technoimg'>
                                            <img src={Img07} alt='' />
                                            </div>
                                            <div className='technocont'>
                                            <h4>Edge Computing</h4>
                                            <ul>
                                              <li>Enterprise Networking</li>
                                              <li>Data Center Networking</li>
                                              <li>Cloud Networking</li>
                                              <li>Optical Networking</li>
                                              <li>Campus Networking</li>
                                              <li>Software-defined Networking</li>
                                              <li>Wide-Area Networking</li>
                                            </ul>
                                            </div>
                                          </div>
                                         <div className='technobox'  data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                          <div className='technoimg'>
                                            <img src={Img08} alt='' />
                                            </div>
                                            <div className='technocont'>
                                            <h4>UNMS & Automation</h4>
                                            <ul>
                                              <li>UNMS</li>
                                              <li>Network Management</li>
                                              <li>Data Center Management</li>
                                              <li>NOC & SOC Products</li>
                                              <li>Cloud Management</li>
                                            </ul>
                                            </div>
                                          </div>
                                         <div className='technobox'  data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                          <div className='technoimg'>
                                            <img src={Img09} alt='' />
                                            </div>
                                            <div className='technocont'>
                                            <h4>Cloud Products</h4>
                                            <ul>
                                              <li>Cloud Management</li>
                                              <li>Cloud Optimization</li>
                                              <li>Cloud Compute</li>
                                              <li>Cloud Storage and backup</li>
                                              <li>Cloud Networking</li>
                                              <li>Cloud Security</li>
                                              <li>Cloud Database</li>
                                              <li>Cloud Telephony and Call</li>
                                              <li>Center</li>
                                            </ul>
                                            </div>
                                          </div>
                                         <div className='technobox'  data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                          <div className='technoimg'>
                                            <img src={Img10} alt='' />
                                            </div>
                                            <div className='technocont'>
                                            <h4>SAAS</h4>
                                            <ul>
                                              <li>Data Storage</li>
                                              <li>Data Backup</li>
                                              <li>Data Protection</li>
                                              <li>Data Management</li>
                                              <li>Data Security</li>
                                            </ul>
                                            </div>
                                          </div>
                                         <div className='technobox'  data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                          <div className='technoimg'>
                                            <img src={Img11} alt='' />
                                            </div>
                                            <div className='technocont'>
                                            <h4>Softwares</h4>
                                            <ul>
                                              <li>Traffic Management</li>
                                              <li>E-Office</li>
                                              <li>ERP Products</li>
                                              <li>Virtualization</li>
                                              <li>Artificial Intelligence</li>
                                              <li>Block Chain</li>
                                              <li>Testing Softwares</li>
                                              <li>Remote Process Automation</li>
                                            </ul>
                                            </div>
                                          </div>
                                         <div className='technobox'  data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                          <div className='technoimg'>
                                            <img src={Img12} alt='' />
                                            </div>
                                            <div className='technocont'>
                                            <h4>End Points</h4>
                                            <ul>
                                              <li>Desktops</li>
                                              <li>laptops</li>
                                              <li>Printers</li>
                                              <li>Thin Clients</li>
                                              <li>Interactive Panels</li>
                                              <li>CCTV Products</li>
                                              <li>Physical Safety and Security</li>
                                            </ul>
                                            </div>
                                          </div>
                                          </div>
                                        </div>
                                    </div>
                            </div>
                    </div>
        </section>
        <section id='business'>
            <div className='business'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                            <div className='title'>
                                <h3>Products and Solution by Business Type</h3>
                                <p>Our products and solutions are engineered at CTAP Research LAB with a focus on engineering simplicity and customer experience.</p>
                            </div>
                            <div className='businesstype'>
                            <div className='protechnology'>
                                       <div className='technobox'  data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                          <div className='btechnoimg'>
                                            <img src={Iconn01} alt='' />
                                            <h4>Service Providers</h4>
                                            </div>
                                            <div className='btechnocont'>
                                            <ul>
                                              <li>NFV</li>
                                              <li>SP Security</li>
                                              <li>Telco Cloud</li>
                                              <li>Data Centers</li>
                                              <li>5G Transport</li>
                                              <li>Edge Computing</li>
                                              <li>Optical Networking</li>
                                              <li>Network Management</li>
                                            </ul>
                                            </div>
                                          </div>
                                         <div className='technobox'  data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                          <div className='btechnoimg'>
                                            <img src={Iconn02} alt='' />
                                            <h4>Enterprise</h4>
                                            </div>
                                            <div className='btechnocont'>
                                            <ul>
                                              <li>SD-WAN</li>
                                              <li>ENT Wi-Fi</li>
                                              <li>ENT Security</li>
                                              <li>ENT Hybrid Cloud</li>
                                              <li>ENT Networking</li>
                                              <li>ENT Collaboration</li>
                                              <li>ENT Data Center</li>
                                              <li>ENT Management</li>
                                            </ul>
                                            </div>
                                          </div>
                                         <div className='technobox'  data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                          <div className='btechnoimg'>
                                            <img src={Iconn03} alt='' />
                                            <h4>Small & Medium</h4>
                                            </div>
                                            <div className='btechnocont'>
                                            <ul>
                                              <li>Hybrid Cloud </li>
                                              <li>Public Cloud </li>
                                              <li>SAAS Solutions</li>
                                              <li>End Point Solutions</li>
                                              <li>Cyber Security</li>
                                              <li>Networking & Mobility</li>
                                              <li>Voice & Collaboration</li>
                                              <li>Management & Automation</li>
                                            </ul>
                                            </div>
                                          </div>
                                         <div className='technobox'  data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                          <div className='btechnoimg'>
                                            <img src={Iconn04} alt='' />
                                            <h4>Government</h4>
                                            </div>
                                            <div className='btechnocont'>
                                            <ul>
                                              <li>Private Cloud</li>
                                              <li>Public Cloud</li>
                                              <li>Hybrid Cloud</li>
                                              <li>HPC and AI/ML</li>
                                              <li>Cyber Security</li>
                                              <li>Data Centers</li>
                                              <li>Networking & Mobility</li>
                                              <li>Voice & Collboration</li>
                                            </ul>
                                            </div>
                                          </div>
                                          </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='solutiontech'>
            <div className='solutiontech'> 
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                          <div className='heading title'>
                              <h4>Solution by Technology</h4>
                              <p>Our products and solutions are engineered at CTAP Research LAB with a focus on engineering simplicity and customer experience.</p>
                          </div>
                          <div className='circleicons' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">                         
                            <ul>
                              <li><span><Analytics /></span><h5>Analytics & Automation</h5></li>
                              <li><span><Artificialsvg /></span><h5>Artificial Intelligence</h5></li>
                              <li><span><Cloudsvg /></span><h5>Cloud</h5></li>
                              <li><span><Collaborationsvg /></span><h5>Collaboration</h5></li>
                              <li><span><Datacentersvg /></span><h5>Data Center</h5></li>
                              <li><span><Networkingsvg /></span><h5>Intent-based Networking</h5></li>
                              <li><span><Thinksvg /></span><h5>Internet of Things</h5></li>
                              <li><span><Mobility /></span><h5>Wireless & Mobility</h5></li>
                              <li><span><Securitysvg /></span><h5>Security</h5></li>
                              <li><span><Softwaresvg /></span><h5>Software</h5></li>
                              <li><span><Softnetworking /></span><h5>Software-networking</h5></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <Contactform /> */}
        <Footer />
    </>
  )
}
