import React from 'react'

export const Securitysvg = (props) => {
  return (
    <>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={80}
    height={80}
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    viewBox="0 0 512 512"
    {...props}
  >
    <path  fill="url(#my-cool-gradient)" d="M170.7 234.7V128c.1-27.3 8.8-47.9 23.1-62.2 14.3-14.2 34.9-23 62.2-23.1 27.3.1 47.9 8.8 62.2 23.1 14.2 14.3 23 34.9 23.1 62.2v106.7H384V128c.1-36.7-12.5-69.4-35.6-92.4C325.4 12.5 292.7-.1 256 0c-36.7-.1-69.4 12.5-92.4 35.6-23.1 23-35.7 55.7-35.6 92.4v106.7h42.7zm85.3 256V512c94.3 0 170.7-76.4 170.7-170.7 0-94.3-76.4-170.7-170.7-170.7-94.3 0-170.7 76.4-170.7 170.7C85.3 435.6 161.7 512 256 512v-42.6c-35.4 0-67.3-14.3-90.5-37.5-23.2-23.2-37.5-55.1-37.5-90.5s14.3-67.3 37.5-90.5c23.2-23.2 55.1-37.5 90.5-37.5s67.3 14.3 90.5 37.5c23.2 23.2 37.5 55.1 37.5 90.5s-14.3 67.3-37.5 90.5c-23.2 23.2-55.1 37.5-90.5 37.5v21.3zM234.7 320v85.3h42.7V320h-42.7zm21.3 21.3v21.3c23.6 0 42.7-19.1 42.7-42.7s-19.1-42.7-42.7-42.7-42.7 19.1-42.7 42.7 19.1 42.7 42.7 42.7V320v21.3z" />
  </svg>
    </>
  )
}
