import React from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import Bgimage from './images/smart-home-banner.webp'
import Icon01 from './images/Home01.webp'
import Icon02 from './images/Home02.webp'
import Icon03 from './images/Home03.webp'
import Icon04 from './images/Home04.webp'
import Icon05 from './images/Home05.webp'
import Icon06 from './images/Home06.webp'
import Icon07 from './images/Home07.webp'
import Icon08 from './images/Home08.webp'

export const Smarthome = () => {
  return (
    <>
        <Header />
        <section id='banner' className='p-0'>
            <div className='banner' style={{backgroundImage:'url('+Bgimage+')'}}>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                        <div className='bannercont' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                              <h2>Embrace the future with one-click automation.</h2>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='homeautomation'>
                <div className='homeautomation'>
                        <div className='container'>
                                <div className='row'>
                                    <div className='web-container'>
                                        <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            <span>Home Automation</span>
                                            <p>With Netplus Smart Home Automation solution make your home smart and fully enriched with technology. Netplus Smart Home Automation integrates these systems with both Wired and Wireless network to give you safe, secure and convenient lifestyle.</p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                </div>
        <div className='plans smarthome'>
          <div className='container'>
            <div className='row'>
                <div className='web-container'>
                      <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                        <h3>Smart homes for smart people</h3>
                      </div>
                      <div className='circleicons' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                            <ul>
                              <li><span><img src={Icon01} alt=''/></span><p>Remote Access</p></li>
                              <li><span><img src={Icon02} alt=''/></span><p>Lighting</p></li>
                              <li><span><img src={Icon03} alt=''/></span><p>HVAC</p></li>
                              <li><span><img src={Icon04} alt=''/></span><p>Curtain & Gate</p></li>
                              <li><span><img src={Icon05} alt=''/></span><p>Multi Room Audio</p></li>
                              <li><span><img src={Icon06} alt=''/></span><p>Home Theatre</p></li>
                              <li><span><img src={Icon07} alt=''/></span><p>Smoke Detector</p></li>
                              <li><span><img src={Icon08} alt=''/></span><p>Video Door Lock</p></li>
                              <li><span><img src={Icon08} alt=''/></span><p>CCTV Security & Surveillance</p></li>
                            </ul>
                        </div>
                    </div>
                    </div>
              </div>
        </div>
      </section>
      <section id='coreadvantages'>
        <div className='coreadvantages'>
            <div className='container'>
              <div className='row'>
                  <div className='web-container'>
                  <div className='heading' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                           <h4>Core Advantages</h4>
                                        </div>
                      <div className='squarecont' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                        <ul>
                          <li><span>Extra Convenience</span><p>Control and automate anywhere anytime</p></li>
                          <li><span>Energy Saving</span><p>Manage all electrical equipments like HVAC, Lights, TV and save energy</p></li>
                          <li><span>Enhance Security</span><p>Keep your property under surveillance on your mobile</p></li>
                        </ul>
                      </div>
                  </div>
              </div>
            </div>
        </div>
      </section>
        {/* <Contactform /> */}
        <Footer />
    </>
  )
}
