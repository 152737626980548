import React from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import { Broadband } from './components/Broadband'

export const Broadbandplan = ( {match} ) => {
  const slugData = match.params.slug;
  return (
    <>
        <Header />
        <section id='banner' className='p-0'>
                    <div className='banner booknow filter m-0 h-120'>                     
                    </div>
            </section>
        <section id='Broadband' className='blckarrow'>
          <div className='container'>
            <div className='row'>
                      <div className='title' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                        <span>Broadband Plans</span>
                        <h3>Your need. Your choice.</h3>
                      </div>
                      <Broadband slugData ={slugData}/>
                    </div>
              </div>
      </section>
        {/* <Contactform /> */}
        <Footer />
    </>
  )
}
