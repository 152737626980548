import { Contactform } from "./components/Contactform";
import { Footer } from "./components/Footer";
import Header from "./components/Header";
import Bgimage from "./images/netplus-hd-bundle.webp";

export const ErrorPage = () => {
  return (
    <>
      <Header />
      <section id="banner" className="p-0">
        <div
          className="banner"
          style={{ backgroundImage: "url(" + Bgimage + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div
                  className="bannercont"
                  data-aos="zoom-in"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                >
                  <h2>Narrate stories as much as you like.</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="retail" className="pt-0">
        <div className="retail">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div
                  className="title"
                  data-aos="fade-up"
                  data-aos-offset="50"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                >
                  <span>Page not Found</span>
                  <h3>Oops! </h3>
                  <h4>It looks like you've taken a wrong turn.</h4>
                  <p>
                    The page you're seeking seems to have slipped through the
                    virtual cracks. We apologize for the detour. Feel free to
                    double-check the web address or return to our homepage.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Contactform /> */}
      <Footer />
    </>
  );
};
