import React from 'react'

export const Mobility = (props) => {
  return (
    <>
      <svg
    xmlns="http://www.w3.org/2000/svg"
    width={90}
    height={90}
    viewBox="0 -3 20 20"
    {...props}
  >
    <title>{"wifi [#1020]"}</title>
    <path
      fill="url(#my-cool-gradient)"
      fillRule="evenodd"
      d="M8.571 12.586 10 14l1.429-1.414a2.034 2.034 0 0 0-2.857 0M5.714 9.758l1.429 1.414a4.074 4.074 0 0 1 5.714 0l1.429-1.414a6.103 6.103 0 0 0-8.572 0M0 4.101l1.428 1.414c4.727-4.679 12.417-4.679 17.143 0L20 4.101c-5.524-5.468-14.477-5.468-20 0m17.142 2.828-1.428 1.414c-3.15-3.119-8.277-3.119-11.429 0L2.857 6.929c3.944-3.905 10.34-3.905 14.285 0"
    />
  </svg>
    </>
  )
}
