import React from 'react'

export const Collaborationsvg = (props) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={90}
                height={90}
                aria-hidden="true"
                aria-labelledby="svgcid-8q7up9-cmcumc"
                data-bbox="43 31 114 138"
                data-type="color"
                viewBox="43 31 114 138"
                {...props}
            >
                <defs>
                    <style />
                </defs>
                <title id="svgcid-8q7up9-cmcumc" />
                <path
                    fill="url(#my-cool-gradient)"
                    d="M129.991 51.284a57.24 57.24 0 0 0-9.926-4.901l-6.43 6.749a48.563 48.563 0 0 1 11.932 5.342c14.398 8.954 23.004 24.48 23.018 41.528a48.866 48.866 0 0 1-7.268 25.695c-6.615 10.742-16.826 18.253-28.582 21.431l13.446-14.112-2.296-9.808-29.733 31.2L134.613 169l-2.296-9.81-13.832-4.99c12.287-4.199 22.899-12.544 29.983-24.055A57.31 57.31 0 0 0 157 99.996c-.02-19.995-10.115-38.205-27.009-48.712zM51.41 99.996a48.855 48.855 0 0 1 7.273-25.69c6.613-10.744 16.826-18.265 28.582-21.435L73.814 66.987l2.299 9.81 29.733-31.205L65.382 31l2.301 9.81 13.841 4.992c-12.294 4.2-22.909 12.54-29.994 24.053A57.333 57.333 0 0 0 43 100.002c.018 20.001 10.113 38.209 27.004 48.714a57.231 57.231 0 0 0 9.928 4.903l6.432-6.751a48.499 48.499 0 0 1-11.932-5.34c-14.401-8.954-23.007-24.48-23.022-41.532z"
                    data-color={1}
                />
            </svg>
        </>
    )
}
