import React from 'react'
import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

function Gallerybox() {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    return (
        <div className="Gallerybox">
            <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
            >
                <a href="https://www.netplus.co.in/assets/img/events/pmwani-chd/PM-WANI_WI-FI_launch_101.jpg">
                    <img src="https://www.netplus.co.in/assets/img/events/pmwani-chd/PM-WANI_WI-FI_launch_101.jpg" alt="PM WANI WI-FI LAUNCH EVENT CHANDIGARH
" />                    
                </a>
                <a href="https://www.netplus.co.in/assets/img/events/pmwani-chd/PM-WANI_WI-FI_launch_102.jpg">
                    <img src="https://www.netplus.co.in/assets/img/events/pmwani-chd/PM-WANI_WI-FI_launch_102.jpg" alt="PM WANI WI-FI LAUNCH EVENT CHANDIGARH
"/>
                </a>
                <a href="https://www.netplus.co.in/assets/img/events/pmwani-chd/PM-WANI_WI-FI_launch_103.jpg">
                    <img src="https://www.netplus.co.in/assets/img/events/pmwani-chd/PM-WANI_WI-FI_launch_103.jpg" alt="PM WANI WI-FI LAUNCH EVENT CHANDIGARH
"/>
                </a>
                <a href="https://www.netplus.co.in/assets/img/events/pmwani-chd/PM-WANI_WI-FI_launch_104.jpg">
                    <img src="https://www.netplus.co.in/assets/img/events/pmwani-chd/PM-WANI_WI-FI_launch_104.jpg" alt="PM WANI WI-FI LAUNCH EVENT CHANDIGARH
"/>
                </a>
                <a href="https://www.netplus.co.in/assets/img/events/pmwani-chd/PM-WANI_WI-FI_launch_105.jpg">
                    <img src="https://www.netplus.co.in/assets/img/events/pmwani-chd/PM-WANI_WI-FI_launch_105.jpg" alt="PM WANI WI-FI LAUNCH EVENT CHANDIGARH
"/>
                </a>
                <a href="https://www.netplus.co.in/assets/img/events/pmwani-chd/PM-WANI_WI-FI_launch_106.jpg">
                    <img src="https://www.netplus.co.in/assets/img/events/pmwani-chd/PM-WANI_WI-FI_launch_106.jpg" alt="PM WANI WI-FI LAUNCH EVENT CHANDIGARH
"/>
                </a>
                <a href="https://www.netplus.co.in/assets/img/events/pmwani-chd/PM-WANI_WI-FI_launch_107.jpg">
                    <img src="https://www.netplus.co.in/assets/img/events/pmwani-chd/PM-WANI_WI-FI_launch_107.jpg" alt="PM WANI WI-FI LAUNCH EVENT CHANDIGARH
"/>
                </a>
            </LightGallery>
        </div>
    );
}
export default Gallerybox;