import React from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'

export const Terms = () => {
  return (
    <>
        <Header />
        <section id='banner' className='p-0'>
                    <div className='banner booknow filter m-0 h-120'>                     
                    </div>
            </section>
            <section id='contentpage'>
                <div className='contentpage'>
                        <div className='container'>
                                <div className='row'>
                                    <div className='web-container'>
                                        <div className='heading'>
                                                <h4>TERMS & CONDITION</h4>
                                        </div>
                                            <p>By using our website, you accept and agreeing to comply with and be bound by the following terms and conditions of use, which together with our Privacy Statement and Copyright & Trademark Infringement.</p>
                                            <ul>
            <li>You must be at least [18] years of age to use our website; and by using our website or agreeing to these terms and conditions, you warrant and represent to us that you are at least [18] years of age.</li>
            <li>This web site contains material including text, photographs and other images and sound, which is protected by copyright and/or other intellectual property rights. All copyright and other intellectual property rights in this material are owned by Netplus Broadband Services (NETPLUS) or have been licensed to it by the owner(s) of those rights so that it can use this material as part of this web site.</li>
            <li>The content of the pages of this website is for and general information and use only. It is subject to change without notice and also change the terms and conditions and disclaimer set out here from time to time. By browsing this web site you are accepting that you are bound by the current terms and conditions and disclaimer and so you should check these each time you revisit the site.</li>
            <li>NETPLUS have their own separate identities but in this web site we may sometimes use “NETPLUS”, “Group, “we” or “us” when we refer to NETPLUS companies in general or where no useful purpose is served by identifying any particular company.</li>
            <li>This web site may include links to external or other web sites from time to time. These links are provided only for your convenience to provide further information. They do not signify that we endorse the website(s). These third party sites have separate and independent privacy policies and, therefore, NETPLUS owe no responsibility for the content of the linked external web site concerned, even if it is a web site owned and operated by another NETPLUS company. NETPLUS is also not responsible for the owners or operators of these web sites or for any services they provide or for the content of their web sites and does not give or enter into any conditions, warranties or other terms or representations in relation to any of these or accept any liability in relation to any of these (including any liability arising out of any claim that the content of any external web site to which this web site includes a link infringes the intellectual property rights of any third party).</li>
            <li>NETPLUS tries to ensure and take all care that all information provided as part of this web site is correct at the time of inclusion on the web site but does not guarantee the accuracy of such information. NETPLUS is not responsible for any loss suffered by you as a consequence of any that action you take or omit to take on the basis of any of the information we provide. We are not liable for content published on the website by users of the website. If any such content appears to be inappropriate to us, we reserve the right to take down such content.</li>
            <li>NETPLUS may, in its sole discretion and without prior notice, terminate access to the Website and block your future access to the Website in eventuality of violation of any terms of these Terms of Use or any other Agreement(s). In case of any irreparable loss caused to company due to any violation of the terms, the company may avail the legal remedies under law for damages, however these remedies are in addition to any other remedies NETPLUS may have at law or in equity.</li>
            <li>NETPLUS reserves it’s the right to refuse access to use the Services offered at the Website to new Users or to terminate access granted to existing Users at any time without according any reasons for doing so.</li>
            <li>We accept Visa and Master Credit Card, Debit Cards and Net banking as mode of payments which are processed through our payment gateway partner. No information pertaining to payment is shared with us as you fill all the payment related information on your bank’s site.</li>
            <li>The information on this web site is provided on an “as is” basis. To the fullest extent permitted by law, this Company:</li>
            <li>excludes all representations and warranties relating to this website and its contents or which is or may be provided by any affiliates or any other third party, including in relation to any inaccuracies or omissions in this website and/or the Company’s literature; and</li>
            <li>excludes all liability for damages arising out of or in connection with your use of this website. This includes, without limitation, direct loss, loss of business or profits (whether or not the loss of such profits was foreseeable, arose in the normal course of things or you have advised this Company of the possibility of such potential loss), damage caused to your computer, computer software, systems and programs and the data thereon or any other direct or indirect, consequential and incidental damages.</li>
            <li>The Company reserves the right to change these conditions from time to time as it sees fit and your continued use of the site will signify your acceptance of any adjustment to these terms. User is required to check terms and conditions at every visit. No notification communication will be done subject to change in terms and conditions.</li>
            <li>Neither party shall be liable to the other for any failure to perform any obligation under any Agreement which is due to an event beyond the control of such party including but not limited to any Act of god, terrorism, war, Political insurgence, insurrection, riot, civil unrest, act of civil or military authority, uprising, earthquake, flood or any other natural or man made eventuality outside of our control, which causes the termination of an agreement or contract entered into, nor which could have been reasonably foreseen. Any Party affected by such event shall forthwith inform the other Party of the same and shall use all reasonable endeavors to comply with the terms and conditions of any Agreement contained herein. These terms and conditions are governed by and to be interpreted in accordance with Law of India and in the event of any dispute arising in relation to these terms and conditions or any dispute arising in relation to the web site, the court at Ludhiana, Punjab will have non-exclusive jurisdiction over such disputes.</li>
            <li>You must refrain from using the website in any way that causes damage to the website or in any unlawful, harmful or fraudulent manner. Any such use of this website may give rise to a claim for damages and/or be a criminal offence.</li>
        </ul>
        {/* <h4>CAF TERMS AND CONDITIONS </h4>
        <strong>Agreement</strong>
        <p>PREAMBLE This Agreement is entered into between the customer (particulars of whom mentioned in the overleaf to this Agreement) and NETPLUS BROADBAND SERVICES PVT. LTD. a company registered and incorporated under Companies Act, 1956 (Act') having its Registered Office: 5th Floor, The Grand Walk Mall, Opp. Gurdev Hospital, Ferozepur Road, Ludhiana-141001 (Herein after referred to as "NBSPL")</p> */}

                                        </div>
                                    </div>
                        </div>
                </div>
            </section>
        <Contactform />
        <Footer />
    </>
  )
}
