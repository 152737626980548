import React from 'react'
import { NavLink } from 'react-router-dom'

export const Years = () => {
  return (
  <>
     <div className='years'>
                                    <ul className='d-flex justify-content-start'>
                                        <li><NavLink to='/' className='tab--active'>2023</NavLink></li>
                                        <li><a>2022</a></li>
                                    </ul>
                                    </div>
  </>
  )
}
