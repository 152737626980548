import React from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import { Link } from 'react-router-dom'

export const Paybill = () => {
    return (
        <>
            <Header />
            <section id='banner' className='p-0'>
                <div className='banner booknow filter m-0 h-120'>
                </div>
            </section>
            <section id='options'>
                <div className='options androidtv'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='planstab' >
                                    <div className='title' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                        <span>Pay bills / Recharge</span>
                                    </div>
                                    <div className='planstabpanel'>
                                        <div className='bundlestn' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            {/* <div className='bundlebox'>
                                                <Link to='/advance-rental-scheme'>
                                                    <h4>Know your Advance Rental</h4>
                                                    <ul>
                                                        <li><strong>Advance Rental </strong></li>
                                                    </ul>
                                                </Link>
                                                <div className='butn'>
                                                    <div className='buttnprop'>
                                                        <Link to='/advance-rental-scheme'><span>Click Here</span></Link>
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className='bundlebox'>
                                                <a href='https://netpay.netplus.co.in/index1.php' target='_blank'>
                                                    <h4>For Postpaid Customers</h4>
                                                    <ul>
                                                        <li><strong>Postpaid Customers </strong></li>
                                                    </ul>
                                                </a>
                                                <div className='butn'>
                                                    <div className='buttnprop'>
                                                        <a href='https://netpay.netplus.co.in/index1.php' target='_blank'><span>Click Here</span></a>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className='bundlebox'>
                                                <a href='https://prepay.netplus.co.in/index1.php' target='_blank'>
                                                    <h4>For Prepaid Customers</h4>
                                                    <ul>
                                                        <li><strong>Prepaid Customers </strong> </li>
                                                    </ul>
                                                </a>
                                                <div className='butn'>
                                                    <div className='buttnprop'>
                                                        <a href='https://prepay.netplus.co.in/index1.php' target='_blank'><span>Click Here</span></a>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className='bundlebox'>
                                                <a href='https://netplus.co.in/iptvpay.html' target='_blank'>
                                                    <h4 >For IPTV Customers</h4>
                                                    <ul>

                                                        <li><strong>IPTV Customers</strong></li>&nbsp;

                                                    </ul>
                                                </a>
                                                <div className='butn'>
                                                    <div className='buttnprop'>
                                                        <a href='https://netplus.co.in/iptvpay.html' target='_blank'><span>Click Here</span></a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='bundlebox'>
                                                <a href='https://billpay.evronservices.co.in' target='_blank'>
                                                    <h4 >For BUNDLE Customers</h4>
                                                    <ul>
                                                        {/* <li><strong>BUNDLE Customers </strong> </li> */}
                                                        <li><strong>Bundle Customers</strong></li>&nbsp;
                                                        <h6>[Broadband+Cable]</h6>
                                                    </ul>
                                                </a>
                                                <div className='butn'>
                                                    <div className='buttnprop'>
                                                        <a href='https://billpay.evronservices.co.in' target='_blank'><span>Click Here</span></a>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Contactform /> */}
            <Footer />
        </>
    )
}
