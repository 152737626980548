import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import Bgimage from './images/jujhar-brand.webp'
import MBgimage from './images/mjujhar-brands.webp'
import { Jujharcont } from './components/Jujharcont'

export const Jujhar = () => {
    const [state, setState] = useState({ turnover: 1, workforce: 1, states: 1, cities: 1 });
  return (
    <>
        <Header />
        <section id='banner' className='p-0'>
            <div className='banner jujhargroup h-auto'>
                  <img src={Bgimage} alt=''className='w-100 desktop-show'/>
                  <img src={MBgimage} alt=''className='w-100 mobile-show'/>
                </div>
        </section>
        <Jujharcont />
        <section id='jujharnumber'>
                    <div className='jujhartitle'>
                            <div className='container'>
                                    <div className='row'>
                                            <div className='web-container'>
                                                <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                                    <span>Jujhar Group In Numbers</span>
                                                </div>
                                            </div>
                                    </div>
                            </div>
                    </div>
                <div className='jujharnumber' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                            <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                        {({ isVisible }) => (
                              <div className="count" data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <div className="App">
                                          <div className="countnum">
                                                {isVisible ? <CountUp start={(state.turnover === 1) ? 1 : 4000} end={4000} duration={3} onEnd={() => setState({ turnover: 4000 })} /> : <CountUp start={1} end={4000} duration={0} />}+
                                                <div className='labelcont'>
                                                <p>WORKFORCE</p>
                                                </div>
                                          </div>

                                    </div>
                                    
                                    <div className="App">
                                          <div className="countnum">
                                                {isVisible ? <CountUp start={(state.cities === 1) ? 1 : 8} end={8} duration={3} onEnd={() => setState({ cities: 8 })} /> : <CountUp start={1} end={8} duration={0} />}+
                                               <div className='labelcont'>
                                               <p>STATES</p>
                                               </div>
                                          </div>
                                    </div>
                                    <div className="App">
                                          <div className="countnum">
                                                {isVisible ? <CountUp start={(state.workforce === 1) ? 1 : 400} end={400} duration={2} onEnd={() => setState({ workforce: 400 })} /> : <CountUp start={1} end={400} duration={0} />}+
                                                <div className='labelcont'>
                                                <p>CITIES</p>
                                                </div>
                                          </div>

                                    </div>
                                    <div className="App">
                                          <div className="countnum">
                                                {isVisible ? <CountUp start={(state.states === 1) ? 1 : 30} end={30} duration={1} onEnd={() => setState({ states: 30})} /> : <CountUp start={1} end={30} duration={0} />} +<p>Years</p>
                                                <div className='labelcont'>
                                                <p>EXPERIENCE</p>
                                                </div>
                                          </div>

                                    </div>
                              </div>

                        )}
                  </VisibilitySensor>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='butn'>
                      <div className='buttnprop'>
                        <a href='https://www.jujhargroup.com/' target='_blank'><span>Visit Jujhargroup.com</span></a>
                      </div>
                    </div>
        </section>
        {/* <Contactform /> */}
        <Footer />
    </>
  )
}
