import React, {useState, useEffect} from 'react'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import axios from 'axios';

export const Numbers = () => {

var decodeHTML = function (html) {
      var txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
};
        
function createMarkup(content) {
      return {__html: decodeHTML(content)};
}

const [state, setState] = useState({ turnover: 1, workforce: 1, states: 1, cities: 1 });
const [pagedata, setPagedata] = useState([]);
    useEffect(() => {
    let mounted = true;
    const loadData = async () => {
       const response = await axios.get(window.weburl + `/api/numbers.php`)
       if(mounted){
           setPagedata(response.data);
       }
    }

    loadData();

    return () => {
        mounted = false;
      };
}, []);
console.log(pagedata);
if (pagedata.length===0) {
    console.log('no data');
    return null;
}
      return (
            <>
                  <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                        {({ isVisible }) => (
                              <div className="count cntNumbers" data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    {pagedata.numbers.map (numberdata => (
                                    <div className="App">
                                          <div className="countnum">
                                                {isVisible ? <CountUp start={(state.turnover === 1) ? 1 : numberdata.fval} end={numberdata.fval} duration={3} onEnd={() => setState({ turnover: numberdata.fval })} /> : <CountUp start={1} end={numberdata.fval} duration={0} />} <p dangerouslySetInnerHTML={createMarkup(numberdata.sval)}></p> {numberdata.tval} <p dangerouslySetInnerHTML={createMarkup(numberdata.fvval)}></p>
                                                <div className='labelcont'>
                                                <p dangerouslySetInnerHTML={createMarkup(numberdata.frval)}></p>
                                                </div>
                                          </div>
                                    </div>
                                    ))}
                              </div>

                        )}
                  </VisibilitySensor>
            </>
      )
}