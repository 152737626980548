import React from 'react'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'

export const Advancerent = () => {
  return (
    <>
        <Header />
            <section id='banner' className='p-0'>
                    <div className='banner booknow filter m-0'>
                        <div className='container'>
                                <div className='row'>
                                        <div className='web-container'>
                                                <div className='title'>
                                                    <br></br>   <br></br>    <br></br>
                                                    <span>Book Now</span>
                                                    <h3>Advance Rental Scheme Request</h3>
                                                </div>
                                                <div className='footerform'>
                                                <div className='form'>
                                                    <form>
                                                <ul>
                                                <li>
                                                    <input type="text" name="name" placeholder='Billing ID*'  required />
                                                </li>
                                                <li>
                                                    <button className='buttnprop'>Get Details</button>
                                                </li>
                                                <li>
                                                    <input type="text" name="name" placeholder='Billing ID*' pattern="[a-zA-Z\s]+" readOnly />
                                                </li>
                                                <li>
                                                    <input type="text" name="name" placeholder='Current Plan*' pattern="[a-zA-Z\s]+" readOnly />
                                                </li>
                                                <li>
                                                    <input type="text" name="name" placeholder='Current Rental*' pattern="[a-zA-Z\s]+" readOnly />
                                                </li>
                                                <li>
                                                   <select>
                                                    <option>Select Rental Scheme</option>
                                                    <option>5+1</option>
                                                    <option>10+2</option>
                                                   </select>
                                                </li>
                                                <li>
                                                    <input type="text" name="name" placeholder='Rental Amount including GST*' readOnly />
                                                </li>
                                                <li><small>*Kindly pay online to avail advance rental scheme</small></li>
                                                <li className='buttnprop'><input type="submit" value="" id="submit_button" /><span id="submit_button_text">Pay Online</span></li>
                                            </ul>
                                            </form>
                                                </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                    </div>
            </section>
            <Contactform />
        <Footer />
    </>
  )
}
