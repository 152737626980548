import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';

export const Ottplans = ({ slugData }) => {
  const slug = (slugData) ? slugData : "";

  var decodeHTML = function (html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  function createMarkup(content) {
    return { __html: decodeHTML(content) };
  }

  const [pagedata, setPagedata] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const response = await axios.get(window.weburl + `api/plans.php?catID=4&pid=${slug}`)
      if (mounted) {
        setPagedata(response.data);
      }
    }

    loadData();

    return () => {
      mounted = false;
    };
  }, []);

  console.log(pagedata);
  if (pagedata.length === 0) {
    console.log('no data');
    return null;
  }

  return (
    <>
      {pagedata.data ?
        <section id='OTT'>
          <div className='androidtv' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
            <div className='container'>
              <div className='row'>
                <div className='web-container'>
                  <div className='title' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                    <span>OTT PLANS</span>
                  </div>
                  <div className='bundlestn'>
                    {pagedata.data.map(getData => {
                      const iconsArray = getData.icon_values.split(',');
                      return <div className='bundlebox'>
                        <h4 dangerouslySetInnerHTML={createMarkup(getData.pname)}></h4>
                        {getData.icon_values ?
                          <div className='apps'>
                            {iconsArray.map((iconsData, index) => {
                              const iconSeparate = iconsData.split('~');
                              return <img src={window.weburl + iconSeparate[1]} alt={iconSeparate[0]} title={iconSeparate[0]} />
                            })}
                          </div>
                          : null}
                        <div className='price ottprice'>
                          <h6>{getData.price}/<small>{getData.ptype}</small></h6>
                        </div>
                        <div className='buttnprop'>
                          <Link to={'/book-now/' + getData.plan_id}><span>Book Now </span></Link>
                        </div>
                      </div>
                    })}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        : null}
    </>
  )
}
