import React from 'react'

export const Thinksvg = (props) => {
  return (
    <>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -73 512 511" {...props}     width={100}
    height={100}  fill="url(#my-cool-gradient)">
    <path d="M313.945 105.379a9.976 9.976 0 0 1-7.074-2.93c-44.691-44.695-117.422-44.695-162.113 0-3.906 3.906-10.238 3.906-14.145 0-3.906-3.902-3.906-10.234 0-14.14 52.492-52.496 137.907-52.496 190.403 0 3.906 3.902 3.906 10.234 0 14.14a9.968 9.968 0 0 1-7.07 2.93zM348.16 71.164a9.966 9.966 0 0 1-7.101-2.957l-.122-.121c-3.921-3.89-3.949-10.223-.058-14.145 3.89-3.918 10.223-3.945 14.14-.054l.239.234c3.89 3.922 3.863 10.254-.059 14.145a9.966 9.966 0 0 1-7.039 2.898zm0 0" />
    <path d="M103.469 71.164a9.968 9.968 0 0 1-7.07-2.93c-3.907-3.902-3.907-10.234 0-14.14 29.648-29.649 67.296-47.785 108.874-52.457 40.352-4.532 81.54 4.582 115.98 25.656 4.708 2.883 6.192 9.039 3.31 13.75-2.883 4.71-9.04 6.191-13.75 3.309-64.426-39.426-146.782-29.61-200.274 23.882a9.961 9.961 0 0 1-7.07 2.93zM225.813 317.578c-34.477 0-68.954-13.121-95.2-39.371-3.906-3.902-3.906-10.234 0-14.14s10.239-3.907 14.145 0c44.691 44.695 117.422 44.695 162.113 0 3.906-3.907 10.238-3.907 14.145 0s3.906 10.238 0 14.14c-26.246 26.25-60.727 39.371-95.204 39.371zm0 0" />
    <path d="M225.934 366.023c-35.754 0-71.008-10.46-100.844-30.16-4.606-3.043-5.875-9.25-2.832-13.855 3.043-4.61 9.246-5.875 13.855-2.836 64.242 42.426 150.446 33.637 204.977-20.89 3.902-3.907 10.234-3.907 14.14 0s3.907 10.238 0 14.14c-29.996 29.996-69.726 48.738-111.882 52.773-5.801.551-11.618.828-17.414.828zM103.816 315.7c-2.539 0-5.078-.962-7.027-2.887l-.422-.418c-3.887-3.922-3.86-10.254.063-14.145 3.922-3.887 10.25-3.86 14.14.063l.27.269c3.93 3.879 3.972 10.211.09 14.145a9.98 9.98 0 0 1-7.114 2.972zM225.672 220.219c-20.379 0-36.961-16.582-36.961-36.961 0-20.38 16.582-36.957 36.96-36.957s36.958 16.578 36.958 36.957-16.578 36.96-36.957 36.96zm0-53.918c-9.352 0-16.961 7.605-16.961 16.957 0 9.351 7.61 16.96 16.96 16.96s16.958-7.609 16.958-16.96c0-9.352-7.606-16.957-16.957-16.957zM36.96 281.137C16.579 281.137 0 264.559 0 244.18V122.34c0-20.38 16.578-36.961 36.96-36.961 20.38 0 36.958 16.582 36.958 36.96v121.84c0 20.38-16.578 36.958-36.957 36.958zm0-175.758c-9.35 0-16.96 7.61-16.96 16.96v121.84c0 9.352 7.61 16.958 16.96 16.958 9.349 0 16.958-7.606 16.958-16.957V122.34c0-9.352-7.61-16.961-16.957-16.961zM436.29 281.137c-17.903 0-32.47-14.563-32.47-32.465v-97.645h-10.78c-17.9 0-32.466-14.566-32.466-32.464 0-17.903 14.567-32.465 32.465-32.465h86.496c17.902 0 32.465 14.562 32.465 32.465 0 17.898-14.563 32.464-32.465 32.464h-10.781v97.645c0 17.902-14.567 32.465-32.465 32.465zm-43.25-175.04c-6.872 0-12.466 5.59-12.466 12.466s5.594 12.464 12.465 12.464h20.781c5.524 0 10 4.477 10 10v107.645c0 6.875 5.594 12.465 12.47 12.465 6.87 0 12.464-5.59 12.464-12.465V141.027c0-5.523 4.476-10 10-10h20.781c6.875 0 12.465-5.593 12.465-12.464 0-6.875-5.59-12.465-12.465-12.465zm0 0" />
    <path d="M225.672 268.742c-47.137 0-85.484-38.347-85.484-85.484 0-47.133 38.347-85.485 85.484-85.485s85.484 38.352 85.484 85.485c0 47.137-38.347 85.484-85.484 85.484zm0-150.969c-36.11 0-65.484 29.375-65.484 65.485s29.375 65.484 65.484 65.484c36.105 0 65.484-29.375 65.484-65.484 0-36.11-29.379-65.485-65.484-65.485zm0 0" />
  </svg>
    </>
  )
}
