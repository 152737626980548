import React from 'react'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Medianav } from './Medianav';
import { Years } from './Years';
import { Link } from 'react-router-dom';
import { Contactform } from './components/Contactform';

export const News = () => {
    return (
        <>
            <Header />
            <section id='banner' className='p-0'>
                <div className='banner booknow filter m-0 h-120'>
                </div>
            </section>            
            <section id='media'>
            <div className='media'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                            <div className='mediarow'>
                                <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <span>News</span>
                                </div>
                                <div className='mediastn d-flex justify-content-between' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <Medianav />
                                    <div className='medright'>
                                        <Years />
                                        <Tabs defaultIndex={0} onSelect={(index) => console.log(index)}>
                                            <TabList className='d-flex justify-content-between'>
                                                <Tab>Jan</Tab>
                                                <Tab>Feb</Tab>
                                                <Tab>Mar</Tab>
                                                <Tab>Apr</Tab>
                                                <Tab>May</Tab>
                                                <Tab>Jun</Tab>
                                                <Tab>Jul</Tab>
                                                <Tab>Aug</Tab>
                                                <Tab>Sep</Tab>
                                                <Tab>Oct</Tab>
                                                <Tab>Nov</Tab>
                                                <Tab>Dec</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <div className='events d-flex justify-content-between flex-wrap'>
                                                        <div className="eventsbox">
                                                            <p>No data found!</p>
                                                            </div>
                                                            </div>
                                            </TabPanel>
                                            <TabPanel>
                                                Feb Gallery
                                            </TabPanel>
                                            <TabPanel>
                                                Mar Gallery
                                            </TabPanel>
                                            <TabPanel>
                                                Apr Gallery
                                            </TabPanel>
                                            <TabPanel>
                                                May Gallery
                                            </TabPanel>
                                            <TabPanel>
                                                Jun Gallery
                                            </TabPanel>
                                            <TabPanel>
                                                Jul Gallery
                                            </TabPanel>
                                            <TabPanel>
                                                Aug Gallery
                                            </TabPanel>
                                            <TabPanel>
                                                Sep Gallery
                                            </TabPanel>
                                            <TabPanel>
                                                Oct Gallery
                                            </TabPanel>
                                            <TabPanel>
                                                Nov Gallery
                                            </TabPanel>
                                            <TabPanel>
                                                Dec Gallery
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
            {/* <Contactform /> */}
            <Footer />
        </>
    )
}
