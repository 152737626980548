import React from 'react'
import { NavLink } from 'react-router-dom'

export const Medianav = () => {
  return (
    <>
         <div className='medleft'>
                                    <ul>
                                      
                                        <li><NavLink to='/events'>Events</NavLink></li>
                                        {/* <li><NavLink to='/media'>Media</NavLink></li> */}
                                        <li><a href='https://jujhargroup.com/newsroom/tag/netplus/' target='_blank'>News</a></li>
                                    </ul>
                                </div>
    </>
  )
}
