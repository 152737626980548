import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Contactform } from './components/Contactform';
import Bgimage from './images/enterprise-banner.webp'
import Aboutus from './images/advantages.webp'
import { Accordion, AccordionItem } from 'react-light-accordion';
import Ericssion from './images/ericsson.webp'
import Nokia from './images/nokia.webp'
import Huawai from './images/huawei.webp'
import Genexis from './images/genexis.webp'
import Zte from './images/zte.webp'
import Cisco from './images/cisco.webp'
import Sterlite from './images/sterlite.webp'
import Oracle from './images/oracle.webp'
import Solarwinds from './images/solarwinds.webp'
import Elite from './images/elitecore.webp'
import H8 from './images/h8-logo.png'
import { Link } from 'react-router-dom';
export const Enterprise = () => {
    const [state, setState] = useState({ turnover: 1, workforce: 1, states: 1, cities: 1 });
    return (
        <>
            <Header />
            <section id='banner' className='p-0'>
                <div className='banner' style={{ backgroundImage: 'url(' + Bgimage + ')' }}>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='bannercont lefttextalign' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                {/* <h2 >Your startup deserves double the speed.</h2> */}
                                 </div>                                                                   
                            </div>                                                                                                          
                        </div>                                                                                                          
                                                                   
                    </div>                                                                                                                  
                </div>                                                                                                          
            </section>                                                                                                          
            <section id='enterprise'>                                                                                                          
                <div className='enterprise'>                                                                                                          
                    <div className='container'>                                                                                                          

                        <div className='row'>
                            <div className='web-container'>

                                <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <span>Enterprise</span>
                                    <h3>Why businesses run better on Netplus?</h3>
                                </div>
                                <div className='smartcont' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <p>Netplus Internet Leased Line offers dedicated, high-speed connectivity to meet your growing business needs. Shared internet lines along with multiple users accessing the network simultaneously, can lead to drop in efficiency. Netplus Internet Fiber leased line empowers you with dedicated bandwidth ensuring reliable high-speed communication and helps your team’s growing need of cloud-based work. These internet leased line plans can be personalized to your requirements. <Link to='/contact-us'>Contact us</Link> today to find out more.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='aboutus'>
                <div className='aboutus'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='heading' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <h4>The best leased line is right here.</h4>
                                </div>
                                <div className='jabtout advantagestn'>
                                    <div className='aboutimg' data-aos="fade-left" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                        <img src={Aboutus} alt='About Jujhar Group' />
                                    </div>
                                    <div className='jabcont'>
                                        <div className='advantagestn' data-aos="fade-right" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            <ul>
                                                <li><h4>Superfast Speed</h4><p>Get high speed of up to 1Gbps with full duplex i.e. 1:1 for both uploads and downloads - even during peak hours.</p></li>
                                                <li><h4>Reliable Performance</h4><p>Get the highly reliable enterprise leased line service with higher uptime – no matter how many other users are on the network.</p></li>
                                                <li><h4>Static IPs</h4><p>Experience a stable business internet network and easily access remote devices with a Static IP pool.</p></li>
                                                <li><h4>Dedicated Access</h4><p>Enjoy fast & seamless connectivity with 24*7 backend support.</p></li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='netplusnumber'>
                <div className='plans smarthome'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='title m-0' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <h3>Why Netplus Internet Leased Line?</h3>
                                </div>
                                <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                                    {({ isVisible }) => (
                                        <div className="count" data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine"><div className="App">
                                            <div className="countnum">
                                                {isVisible ? <CountUp start={(state.turnover === 1) ? 1 : 100000} end={100000} duration={3} onEnd={() => setState({ turnover: 100000 })} /> : <CountUp start={1} end={100000} duration={0} />}+ <p>Km</p>
                                                <div className='labelcont'>
                                                    <p>SOLID INFRASTRUCTURE OF FIBER NETWORK</p>
                                                </div>
                                            </div>
                                        </div>
                                            <div className="App">
                                                <div className="countnum">
                                                    {isVisible ? <CountUp start={(state.workforce === 1) ? 1 : 3000} end={3000} duration={2} onEnd={() => setState({ workforce: 3000 })} /> : <CountUp start={1} end={3000} duration={0} />}+
                                                    <div className='labelcont'>
                                                        <p>Premium Enterprise Customers</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="App">
                                                <div className="countnum">
                                                    <p>NOC & DWDN</p>
                                                    <div className='labelcont'>
                                                        <p>State-of-the-art secured network</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="App">
                                                <div className="countnum">
                                                    {isVisible ? <CountUp start={(state.workforce === 1) ? 1 : 4500} end={4500} duration={2} onEnd={() => setState({ workforce: 3000 })} /> : <CountUp start={1} end={4500} duration={0} />}+
                                                    <div className='labelcont'>
                                                        <p>Dedicated workforce</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="App">
                                                <div className="countnum">
                                                    {isVisible ? <CountUp start={(state.cities === 1) ? 1 : 7} end={7} duration={3} onEnd={() => setState({ cities: 7 })} /> : <CountUp start={1} end={7} duration={0} />} <p>Million</p> +
                                                    <div className='labelcont'>
                                                        <p>Home passes of FTTH</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )}
                                </VisibilitySensor>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='partner'>
                <div className='partner'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='partnerstn'>
                                    <div className='partnerbox' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                        <div className='heading'>
                                            <h4>OEM Partner</h4>
                                        </div>
                                        <ul>
                                            <li><img src={Cisco} alt='Cisco' /></li>
                                            <li><img src={Nokia} alt='Nokia' /></li>
                                            {/* <li><img src={Huawai} alt='Huawai'/></li> */}
                                            <li><img src={Genexis} alt='Genexis' /></li>
                                            {/* <li><img src={Zte} alt='ZTE'/></li> */}
                                            <li><img src={Ericssion} alt='Ericssion' /></li>
                                        </ul>
                                    </div>
                                    <div className='partnerbox' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                        <div className='heading'>
                                            <h4>IT Partner</h4>
                                        </div>
                                        <ul>
                                            <li><img src={Sterlite} alt='Sterlite Tech' /></li>
                                            <li><img src={Oracle} alt='Oracle' /></li>
                                            <li><img src={Solarwinds} alt='Solarwinds' /></li>
                                            <li><img src={Elite} alt='Elite Core' /></li>
                                            <li><img src={H8} alt='Height 8'/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='faq'>
                <div className='faq'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='heading' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <h4>Frequently Asked Questions</h4>
                                </div>
                                <div className='faqcont' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                    <Accordion atomic={true}>

                                        <AccordionItem title="What is an internet leased line?">
                                            <div className='accordiancont'>
                                                <p>Unlike broadband, which works on a shared platform, a leased line gives you a single line all to yourself. Therefore, no busy periods or dips in data service to worry about. It enables consistent, reliable & high speed internet connectivity for your business.</p>
                                            </div>
                                        </AccordionItem>
                                        <AccordionItem title="What is the advantage of a leased line network?">
                                            <div className='accordiancont'>
                                                <p>A leased line network is a dedicated network solution that provides reliable and uninterrupted internet access for your business. These networks improve business growth and productivity by offering high-performance, agile, and secure connectivity that you can rely on. Additionally, our leased line solutions can be customized to your company’s unique needs.</p>
                                            </div>
                                        </AccordionItem>
                                        <AccordionItem title="How can I set up a dedicated leased line?">
                                            <div className='accordiancont'>
                                                <p>A dedicated business leased line is easy to deploy and our network experts can help you create a business internet solution that’s tailor-made to your organization.</p>
                                            </div>
                                        </AccordionItem>
                                        <AccordionItem title="Why is Netplus Internet leased line ideal for my business?">
                                            <div className='accordiancont'>
                                                <p>Netplus internet leased line is exclusively designed to support the unique needs of a new age businesses. The superior efficiency, reliability, and scalability of our leased line solution enhances business agility while also improving productivity by ensuring uninterrupted and hassle-free connectivity.</p>
                                            </div>
                                        </AccordionItem>
                                        <AccordionItem title="What is the difference between broadband and leased line?">
                                            <div className='accordiancont'>
                                                <p>When you use a broadband connection, you share your bandwidth with others. Whereas an internet leased line is a dedicated line just for you. That means you don’t need to share it with anyone else. There are no risk of lags when you’re working transferring file on the cloud.</p>
                                            </div>
                                        </AccordionItem>
                                    </Accordion>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Contactform /> */}
            <Footer />
        </>
    )
}
