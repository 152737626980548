import React from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'

export const Login = () => {
  return (
        <>
            <Header />
            <section id='banner' className='p-0'>
                    <div className='banner booknow filter m-0 h-120'>                     
                    </div>
            </section>
            <section id='login'>
                <div className='login contactpage'>
                <div className='container'>
                                <div className='row'>
                                        <div className='web-container'>
                                                <div className='title' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                                    <span>My Account</span>
                                                </div>
                                                <div className='footerform'>
                                                <div className='form' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                                    <form>
                                                <ul>
                                                <li>
                                                    <input type="text" name="name" placeholder='Username' required />
                                                </li>
                                                <li>
                                                    <input type="text" name="name" placeholder='Password' />
                                                </li>
                                                <li className='buttnprop'><input type="submit" value="" /><span id="submit_button_text">Submit</span></li>
                                            </ul>
                                            </form>
                                                </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
            </section>
            {/* <Contactform /> */}
            <Footer />
        </>
  )
}
