import React from 'react'

export const Cloudsvg = (props) => {
  return (
    <>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={90}
    height={90}
    viewBox="0 0 466.337 466.337"
    {...props}
  >
    <path fill="url(#my-cool-gradient)" d="M233.168 0C104.604 0 0 104.604 0 233.168c0 128.565 104.604 233.169 233.168 233.169 128.565 0 233.169-104.604 233.169-233.169C466.337 104.604 361.733 0 233.168 0zm-9.184 441.874c-22.321 0-46.405-41.384-59.045-107.815h118.067c-12.635 66.431-36.69 107.815-59.022 107.815zm-62.87-131.73c-2.738-19.991-4.437-41.781-4.881-65.018H291.74c-.443 23.237-2.148 45.027-4.869 65.018H161.114zM24.521 245.126h107.704c.443 21.883 2.09 43.859 4.887 65.018H38.768c-8.075-20.318-12.95-42.178-14.247-65.018zM223.984 24.464c21.982 0 45.687 40.14 58.484 104.877h-116.97c12.788-64.737 36.498-104.877 58.486-104.877zm62.479 128.781c2.978 20.785 4.811 43.596 5.277 67.966H156.222c.467-24.37 2.295-47.169 5.272-67.966h124.969zm-154.237 67.966H24.521c1.354-23.926 6.568-46.836 15.332-67.966h97.656c-3.047 22.075-4.828 45.067-5.283 67.966zm183.523 23.915h126.065c-1.296 22.84-6.188 44.7-14.246 65.018H310.855c2.791-21.159 4.45-43.135 4.894-65.018zm0-23.915c-.468-22.898-2.254-45.891-5.29-67.966h116.023c8.77 21.13 13.978 44.04 15.332 67.966H315.749zm98.847-91.881H306.617c-7.894-42.067-20.727-78.844-38.195-102.222 62.53 10.691 115.638 49.097 146.174 102.222zM176.073 32.036c-15.7 23.459-27.348 58.1-34.699 97.305H51.741C78.657 82.505 123.064 47.1 176.073 32.036zM49.96 334.058h90.895c7.311 40.403 19.133 76.205 35.219 100.26-54.13-15.414-99.402-51.94-126.114-100.26zm218.45 105.164c17.865-23.938 30.874-61.889 38.697-105.164h109.274c-30.231 54.685-84.261 94.281-147.971 105.164z" />
  </svg>
    </>
  )
}
