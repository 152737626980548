import React from 'react'

export const Datacentersvg = (props) => {
  return (
    <>
     <svg
    xmlns="http://www.w3.org/2000/svg"
    width={90}
    height={90}
    aria-hidden="true"
    aria-labelledby="svgcid--3vaxnd-ae1oxw"
    data-bbox="39.5 39.5 121 121"
    data-type="color"
    viewBox="39.5 39.5 121 121"
    {...props}
  >
    <defs>
      <style />
    </defs>
    <title id="svgcid--3vaxnd-ae1oxw" />
    <path
      fill="url(#my-cool-gradient)"
      d="M100.002 160.5C66.64 160.5 39.5 133.358 39.5 99.998 39.5 66.64 66.64 39.5 100.002 39.5c33.358 0 60.498 27.139 60.498 60.498 0 33.36-27.14 60.502-60.498 60.502zm0-112.573c-28.716 0-52.078 23.359-52.078 52.071 0 28.718 23.362 52.079 52.078 52.079 28.713 0 52.074-23.361 52.074-52.079 0-28.712-23.361-52.071-52.074-52.071z"
      data-color={1}
    />
    <path
      fill="url(#my-cool-gradient)"
      d="M104.212 118.183h-8.425v-48.12h8.425v48.12zM104.212 126.153v8.423h-8.424v-8.423h8.424z"
      data-color={1}
    />
  </svg>
    </>
  )
}
