import React from 'react'

export const Softnetworking = (props) => {
  return (
    <>
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={85}
    height={85}
    viewBox="0 0 64 64"
    {...props}
  >
    <g data-name="Layer 21"  fill="url(#my-cool-gradient)">
      <path d="M58 1H6a5.006 5.006 0 0 0-5 5v40a5.006 5.006 0 0 0 5 5h15.719l-1.5 6H19a4 4 0 0 0-4 4v1a1 1 0 0 0 1 1h32a1 1 0 0 0 1-1v-1a4 4 0 0 0-4-4h-1.219l-1.5-6H58a5.006 5.006 0 0 0 5-5V6a5.006 5.006 0 0 0-5-5zM47 61H17a2 2 0 0 1 2-2h26a2 2 0 0 1 2 2zm-5.281-4H22.281l1.5-6h16.438zM61 46a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h52a3 3 0 0 1 3 3z" />
      <path d="M58 5H6a1 1 0 0 0-1 1v36a1 1 0 0 0 1 1h52a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1zm-1 2v6H7V7zM7 41V15h50v26zM51 45h2v2h-2zM55 45h2v2h-2z" />
      <path d="M53 9h2v2h-2zM49 9h2v2h-2zM45 9h2v2h-2zM15 27h-1a1 1 0 0 1-1-1v-1a1 1 0 0 0-.293-.707L11.414 23l1.293-1.293A1 1 0 0 0 13 21v-1a1 1 0 0 1 1-1h1v-2h-1a3 3 0 0 0-3 3v.586l-1.707 1.707a1 1 0 0 0 0 1.414L11 25.414V26a3 3 0 0 0 3 3h1zM53 30.586V30a3 3 0 0 0-3-3h-1v2h1a1 1 0 0 1 1 1v1a1 1 0 0 0 .293.707L52.586 33l-1.293 1.293A1 1 0 0 0 51 35v1a1 1 0 0 1-1 1h-1v2h1a3 3 0 0 0 3-3v-.586l1.707-1.707a1 1 0 0 0 0-1.414zM16 20h33v2H16zM16 24h29v2H16zM47 24h2v2h-2zM15 30h34v2H15zM15 34h30v2H15zM47 34h2v2h-2z" />
    </g>
  </svg>
    </>
  )
}
