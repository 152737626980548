import React from 'react'
import Header from './components/Header'
import { Contactform } from './components/Contactform'
import { Footer } from './components/Footer'
import { Ottplans } from './components/Ottplans'

export const Ott = ( {match} ) => {
  const slugData = match.params.slug;
  return (
    <>
        <Header />     
        <section id='banner' className='p-0'>
                    <div className='banner booknow filter m-0 h-120'>                     
                    </div>
            </section>     
        <Ottplans slugData ={slugData}/>
        {/* <Contactform /> */}
        <Footer />
    </>
  )
}
