import React, {useState, useEffect} from 'react'
import Slider from 'react-slick';
import Plan_bg from "../images/Plan_Bg.webp"
import { Link } from 'react-router-dom';
import { Ottslider } from './Ottslider';
import axios from 'axios';

export const Entertainment = ( {slugData} ) => {

  const slug = (slugData) ? slugData : "";

  var decodeHTML = function (html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };
  
  function createMarkup(content) {
      return {__html: decodeHTML(content)};
  }

    var settings3 = {
        dots: false,
        navigation: true,
        loop: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        speed: 300,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1340,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      };
  
      const [pagedata, setPagedata] = useState([]);
    useEffect(() => {
    let mounted = true;
    const loadData = async () => {
       const response = await axios.get(window.weburl + `api/plans.php?catID=2&pid=${slug}`)
       if(mounted){
           setPagedata(response.data);
       }
    }

    loadData();

    return () => {
        mounted = false;
      };
}, []);

console.log(pagedata);
if (pagedata.length===0) {
    console.log('no data');
    return null;
}

  return (
    <>
    {pagedata.data ?
    <div className='planslider' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                        <Slider {...settings3}>
                        {pagedata.data.map(getData => {
                          const newValues = getData.features.split('|_');
                          return <div>
                            <div className='plansitem'>
                              <div className='ptag'>
                              <h5 dangerouslySetInnerHTML={createMarkup(getData.pname)}></h5>
                              </div>
                              <div className='image_plans_div'>
                                <div className="image_plan">
                                  <img src={Plan_bg} alt="plan" />
                                </div>
                                <p dangerouslySetInnerHTML={createMarkup(getData.ptitle)}></p>
                              </div>
                              <div className='price'>
                                <div className='sprice m-0'>
                                <h6>{getData.price}</h6>
                                </div>
                              </div>
                              <div className='entertainment'>
                                <Ottslider geticonValues={getData.icon_values}/>
                              </div>
                              <div className='price'>
                                {getData.features?
                                  <ul>
                                  {newValues.map(featuresValue => {
                                    return <>
                                    {featuresValue ?
                                      <li>{featuresValue}</li>
                                      : null}
                                    </>
                                    })}
                                </ul>
                                : null}
                                <div className='buttnprop'>
                                  <Link to={'/book-now/' + getData.plan_id}><span>Book Now</span></Link>                                   
                                </div>
                              </div>
                            </div>
                          </div>
                        })}
                        </Slider>
                      </div>
                      : null}
    </>
  )
}
