import React from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import Gallerybox from './Gallerybox'
import { Link } from 'react-router-dom'

export const Eventdetails = () => {
  return (
    <>
        <Header />
        <section id='banner' className='p-0'>
                <div className='banner booknow filter m-0 h-120'>
                </div>
            </section>
        <section id='gallery'>
            <div className='gallery'>
                    <div className='container'>
                            <div className='row'>
                                    <div className='web-container'>
                          <div className='buttnprop'>
                           <Link to='/events'><span>Back</span></Link>
                            </div>
                                            <div className='heading'>
                                                <h4>PM Wani Wi-Fi launch event chandigarh</h4>
                                            </div>
                                            <Gallerybox />
                                    </div>
                            </div>
                    </div>
            </div>
        </section>
        {/* <Contactform /> */}
        <Footer />
    </>
  )
}
