import React from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import Bgimage from './images/security-camera.webp'
import Icon01 from './images/Home01.webp'
import Icon02 from './images/Home02.webp'
import Icon03 from './images/Home03.webp'
import Icon04 from './images/Home04.webp'
import Icon05 from './images/Home05.webp'
import { Link } from 'react-router-dom'

export const Magicbox = () => {
  return (
    <>
        <Header/>
        <section id='banner' className='p-0'>
            <div className='banner' style={{backgroundImage:'url('+Bgimage+')'}}>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                          <div className='bannercont' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                              <h2>Enhance wifi coverage in your home & office</h2>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='smartcity'>
            <div className='smartcity'>
                    <div className='container'>
                            <div className='row'>
                                    <div className='web-container'>
                                                <div className='title m-0' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                                <span>NETPLUS MAGIC BOX</span>
                                                <h3>What Netplus Magic Box Can Do For You?</h3>
                                            </div>
                                            <div className='smartcont' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            <p>Netplus Magic Box is a device that helps you to create managed Wi-Fi and give super-fast network connectivity to your staff, guests or customers. This magic box has been designed to provide phone-number based authentication with 100% compliance and security as per the Govt. of India rules (TRAI Compliant). It prevents internet misuse with just a simple App; you can set data limit, time limit, user blocking on your Wi-Fi service anywhere anytime. </p>
                                            <p>Netplus Magic Box also comes with a unique feature of personalized branding like showing logo, deals & offers, menu, etc. for your customers on the Wi-Fi login page. Also you can get your own verified customer data for various marketing activities with this device.</p>
                                            </div>
                                    </div>
                            </div>
                    </div>
            </div>
        </section>
        <section id='netplusadvantage' className='pb-0'>
                <div className='netplusadvantages'>
                        <div className='container'>
                                <div className='row'>
                                    <div className='web-container'>
                                            <div className='heading' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            
                                            <h4>Netplus Magic Box Features?</h4>
                                            </div>
                                            <div className='advantages' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon01} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Best Wifi coverage without any glitches </p>
                                                    </div>
                                                </div>
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon02} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Easy plug and play setup with existing broadband modem  </p>
                                                    </div>
                                                </div>
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon03} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Set daily data limits & time for users</p>
                                                    </div>
                                                </div>
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon04} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Manage the device with a single Netplus login</p>
                                                    </div>
                                                </div>
                                                <div className='advantagesbox'>
                                                    <div className='advantageicon'>
                                                        <span><img src={Icon05} alt='' /></span>
                                                    </div>
                                                    <div className='advantagecont'>
                                                        <p>Choice of personalized branding on Wifi login page</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                        </div>
                </div>
        </section>
        <section id='magicbox'>
            <div className='hdbundle'>
                    <div className='container'>
                            <div className='row'>
                                    <div className='web-container'>
                        <div className='title'>
                        <span>PLANS</span>
                                            <h3>Ready to get started</h3>
                                        </div>
                                        <div className='bundlestn'>
                                            <div className='bundlebox'>
                                                <h4><i className='finr'></i>499</h4>
                                                <div className='price'>
                                                <ul>
                                                    <li><strong>1 Month </strong>Validity </li>
                                                    <li><i className='finr'></i>1000 Refundable Security Deposit</li>                                                  
                                                </ul>
                                                </div>
                      <div className='buttnprop'>
                      <Link to='/book-now'><span>Book Now </span></Link>
                      
                    </div>
                                            </div>
                                            <div className='bundlebox'>
                                                <h4><i className='finr'></i>2495</h4>
                                                <div className='price'>
                                                <ul>
                                                    <li><strong>6 Months</strong> Validity </li>
                                                    <li><i className='finr'></i>500 Refundable Security Deposit</li>                                                  
                                                </ul>
                                                </div>
                      <div className='buttnprop'>
                      <Link to='/book-now'><span>Book Now </span></Link>
                      
                    </div>
                                            </div>
                                            <div className='bundlebox'>
                                                <h4><i className='finr'></i>4990</h4>
                                                <div className='price'>
                                                <ul>
                                                    <li><strong>12 Months</strong> Validity </li>
                                                    <li>No Security Deposit</li>                                                  
                                                </ul>
                                                </div>
                      <div className='buttnprop'>
                      <Link to='/book-now'><span>Book Now </span></Link>
                      
                    </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                            </div>
                    </div>
            </div>
        </section>
        {/* <Contactform /> */}
        <Footer/>
    </>
  )
}
