import React, {useState, useEffect, useRef} from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Contactform } from './components/Contactform'
import Bgimage from './images/retail-banner.webp'
import Icon01 from './images/01.webp'
import Icon02 from './images/02.webp'
import Icon03 from './images/03.webp'
import Icon04 from './images/04.webp'
import App from './images/plan.webp'
import { Accordion, AccordionItem } from 'react-light-accordion';
import { Entertainment } from './components/Entertainment'
import { Broadband } from './components/Broadband'
import { Ottplans } from './components/Ottplans'
import axios from 'axios';

export const Retail = () => {
var decodeHTML = function (html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
};
function createMarkup(content) {
  return {__html: decodeHTML(content)};
}

  const [states, setStates] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedPlans, setSelectedPlans] = useState('');

  useEffect(() => {
    axios.get(window.weburl + 'api/get_states.php')
      .then(response => setStates(response.data))
      .catch(error => console.log(error));
  }, []);

  function getPlans() {
    axios.get(window.weburl + `api/get_plans.php?sid=${selectedState}&cid=${selectedType}`)
      .then(response => setPlans(response.data))
      .catch(error => console.log(error));
      if ((selectedState!='') && (selectedType!='') && (selectedPlans!='')) {
        const element = document.getElementById('submit_button_text');
        element.classList.remove('disabled');
      }
  }
  const handleSubmit = ()  => {
    if ((selectedState!='') && (selectedType!='') && (selectedPlans!='')) {
          if(selectedType == '3') {
            window.location.href="broadband-plans/"+selectedPlans;
          }
          else if(selectedType == '2') {
            window.location.href="entertainment-plans/"+selectedPlans;
          }
          else {
            window.location.href="ott-plans/"+selectedPlans;
          }
      }
    else {
      return false;
    }
  }

  const [pincode, setPincode] = useState('');
  const [selectedPincode, setselectedPincode] = useState('');
  
  function getpincode(event) {
    const pLength = event.length;
    if(pLength === 6) {
      axios.get(window.weburl + `api/get_pincode.php?pc=${event}`)
      .then(response => setPincode(
        (response.data==true) ? "<label>We are available in your area</label>" : "<label class='error'>We are not available in your area</label>"
      ))
      .catch(error => console.log(error));
    }
    }

  return (
    <>
        <Header />
        <section id='banner' className='p-0'>
            <div className='banner' style={{backgroundImage:'url('+Bgimage+')'}}>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                          <div className='bannercont' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                              <h2>Narrate stories as much as you like.</h2>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='retail' className='pt-0'>
            <div className='retail'>
                <div className='container'>
                  <div className='row'>
                    <div className='web-container'>
                        <div className='title' data-aos="fade-up" data-aos-offset="50" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                          <span>Home</span>
                            <h3>Stay ahead of time</h3>
                            <h4>unlimited data & calls</h4>
                            <p>Experience the ultimate thrill of lightning-fast 1Gbps internet speed with Netplus, powered by cutting-edge fiber-optic technology. Unlock unlimited broadband plans, seamless internet & entertainment.</p>
                        </div>
                        <div className='circleicons' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                            <div className='heading'>
                              <h4>Why Netplus Fiber?</h4>
                            </div>
                            <ul>
                              <li><span><img src={Icon01} alt=''/></span><h5>Super fast internet </h5><p>Explore at lightning fast speed</p></li>
                              <li><span><img src={Icon02} alt=''/></span><h5>Secured Network </h5><p>Trusted by majority of users in Punjab</p></li>
                              <li><span><img src={Icon03} alt=''/></span><h5>Unlimited Packages </h5><p>Get exciting combo deals for TV, Calling and broadband.</p></li>
                              <li><span><img src={Icon04} alt=''/></span><h5>Affordable Pricing </h5><p>Entertainment is 2X when you pay less</p></li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='app'>
        <div className='app'>
          <div className='container'>
            <div className='row'>
            <div className='heading' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                              <h4>Your Net. Your Plan.</h4>
                            </div>
              <div className='gradientapp'>
                <div className='appleft form' data-aos="fade-left" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                    <form>
                      <ul>
                      <li>
                        <span>Select state</span>
                        <select name="selectedState" id="selectedState" value={selectedState} onChange={event => { setSelectedState(event.target.value); setSelectedPlans(''); }}>
                          <option value=""></option>
                          {states.map(state => (
                          <option key={state.stateid} value={state.stateid}>{state.sname}</option>
                          ))}
                          </select>
                      </li>
                      <li>
                        <span>Choose plan type</span>
                        <select id="selectedType" value={selectedType} onChange={event => { setSelectedType(event.target.value); setSelectedPlans(''); }}>
                          <option value=""></option>
                          <option value="3">Broadband</option>
                          <option value="2">Entertainment</option>
                          <option value="4">OTT</option>
                          </select>
                      </li>  
                      <li>
                        <span>Choose pack</span>
                        <select name="selectedPlans" id="selectedPlans" value={selectedPlans} onChange={event => setSelectedPlans(event.target.value)} onClick={getPlans}>
                          <option value=""></option>
                          {plans.map(plan => (
                          <option key={plan.sno} value={plan.sno} dangerouslySetInnerHTML={createMarkup(plan.pname + ' ' + plan.ptitle)}></option>
                          ))}
                          </select>
                        </li>
                      </ul>
                  <div className='buttnprop' onClick={handleSubmit}>
                    <span id="submit_button_text" className='disabled'>Know more</span>
                  </div>
                    </form>
                </div>
                <div className='apprgt '>
                  <div className='appimg' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                    <img src={App} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='broadband' className='blckarrow pb-0'>
          <div className='broadband'  data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
            <div className='container'>
                <div className='row'>
                  <div className='planstabpanel'>
                      <div className='title' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                        <span>BROADBAND PLANS</span>
                        <h3>Your need. Your choice.</h3>
                        <p>Broadband plans starting at <i className='finr'></i>499. Get uninterrupted speed and unlimited data to enjoy benefits of internet and entertainment under one place. </p>
                      </div>
                      <Broadband />
                    </div>
                  </div>
                </div>
            </div>
      </section>
      <Ottplans />  
      <section id='entertainment'>
        <div className='plans'  data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
          <div className='container'>
            <div className='row'>
              <div className='planstab'>
                    <div className='planstabpanel'>
                      <div className='title' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                        <span>ENTERTAIMENT PLANS</span>
                        <h3>Your need. Your choice.</h3>
                      </div>
                      <Entertainment />
                    </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='squareshape'>
        <div className='squareshape'>
            <div className='container'>
              <div className='row'>
                  <div className='web-container'>
                      <div className='squarecont'>
                        <ul data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                          <li><span>Super-fast Internet Speed Up to <strong>1Gbps</strong></span></li>
                          <li><span>Dual Band Routers <strong>5Ghz</strong> Band</span></li>
                          <li><span>Ultra 4K Andriod TV Box Just at <strong><i className='finr'></i>2500</strong></span></li>
                          <li><span>10 OTT Apps Just in <strong><i className='finr'></i>99</strong></span></li>
                          <li><span>14 OTT Apps Just in <strong><i className='finr'></i>199</strong></span></li>                          
                        </ul>
                      </div>
                  </div>
              </div>
            </div>
        </div>
      </section>
      <section id='faq'>
        <div className='faq'>
            <div className='container'>
                <div className='row'>
                    <div className='web-container'>
                    <div className='heading' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            <h4>Frequently Asked Questions</h4>
                                        </div>
                                        <div className='faqcont' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                            <Accordion atomic={true}>

                                                <AccordionItem title="How to get Netplus broadband connection?">
                                                    <div className='accordiancont'>
                                                        <p>Steps to get Netplus broadband connection:</p>
                                                        <ul>
                                                          <li>Visit (netplus website URL)</li>
                                                          <li>Select best plan that suits your needs</li>
                                                          <li>Fill the new connection form & submit it.</li>
                                                        </ul>
                                                        <p>Our service provider will contact you within 24 hrs  to install new connection at your preferred address.</p>
                                                    </div>
                                                </AccordionItem>
                                              <AccordionItem title="How long does it take for a new connection to become active?">
                                                    <div className='accordiancont'>
                                                        <p>It takes approximately 36-48 hours for a new connection to become active.</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="Will I get the maximum broadband speed as shortlisted in the plan?">
                                                    <div className='accordiancont'>
                                                        <p>The speed of the internet shall vary as per your exact location.</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="Is Netplus Fiber available in my area?">
                                                    <div className='accordiancont'>
                                                        <p>Currently Netplus Fiber is available only in selected areas. If you want to check the availability of our service in your Home/Office/Locality, check your area pincode.</p>
                                                        <p><input type='text' placeholder='Enter your pincode here.' name="selectedPincode" id="selectedPincode" value={selectedPincode} onChange={event => setselectedPincode(event.target.value)} onKeyUp={event => [getpincode(event.target.value), setPincode('')]}/>
                                                        <span className={pincode ? "" : "hideMsg"} dangerouslySetInnerHTML={createMarkup(pincode)}></span>
                                                        </p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="Why is Netplus broadband best for home or office?">
                                                    <div className='accordiancont'>
                                                        <p>The Netplus Fiber broadband uses cutting-edge Fiber Optics technology that transmits light instead of electricity, thus providing uninterrupted connectivity and speed. So, whether you are streaming or working from home, you will get a faster and a seamless internet experience every day.</p>
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem title="How to change my existing Netplus Broadband Plan?">
                                                    <div className='accordiancont'>
                                                        <p>Go to your Netplus Broadband Website. You get the option ‘Login’ on the top. Click on the same and enter your login id and then click ‘Change Plan’ that suits your needs.</p>
                                                    </div>
                                                </AccordionItem>
                                            </Accordion>

                                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
        {/* <Contactform /> */}
        <Footer />
    </>
  )
}
